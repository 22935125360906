import * as THREE from 'three';

const StarsShader = {
    vertexShader: `
        attribute float size;
        attribute vec3 color;
        varying vec3 vColor;
        uniform float time;
        
        void main() {
            vColor = color;
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            
            float twinkle = sin(time * 2.0 + position.x * 100.0) * 0.5 + 0.5;
            
            gl_PointSize = size * (100.0 / -mvPosition.z) * twinkle;
            gl_Position = projectionMatrix * mvPosition;
        }
    `,
    fragmentShader: `
        varying vec3 vColor;
        
        void main() {
            vec2 xy = gl_PointCoord.xy - vec2(0.5);
            float ll = length(xy);
            
            float alpha = smoothstep(0.5, 0.4, ll);
            float glow = exp(-ll * 8.0) * 0.7;
            
            gl_FragColor = vec4(vColor * (1.0 + glow), alpha);
        }
    `,
    uniforms: {
        time: { value: 0 },
        pixelRatio: { value: window.devicePixelRatio }
    },
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
};

export { StarsShader }; 