import * as THREE from 'three';

const FadeShader = {
    uniforms: {
        tDiffuse: { value: null },
        fadeAmount: { value: 0.0 },
        fadeColor: { value: new THREE.Color(0x000000) }
    },
    vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float fadeAmount;
        uniform vec3 fadeColor;
        varying vec2 vUv;
        
        void main() {
            vec4 texel = texture2D(tDiffuse, vUv);
            vec3 adjustedColor = pow(fadeColor, vec3(2.2));
            vec3 finalColor = mix(texel.rgb, adjustedColor, fadeAmount);
            gl_FragColor = vec4(finalColor, 1.0);
        }
    `
};

export { FadeShader }; 