export const FilmGrainShader = {
  uniforms: {
    'tDiffuse': { value: null },
    'time': { value: 0.0 },
    'intensity': { value: 0.15 },
    'speed': { value: 1.0 },
    'grainSize': { value: 1.0 }
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform float time;
    uniform float intensity;
    uniform float speed;
    uniform float grainSize;
    varying vec2 vUv;

    float random(vec2 p) {
      vec2 k1 = vec2(
        23.14069263277926,
        2.665144142690225
      );
      return fract(
        cos(
          dot(p, k1) + time * speed * 0.1
        ) * 12345.6789
      );
    }

    void main() {
      vec4 color = texture2D(tDiffuse, vUv);
      
      vec2 grainUv = vUv * vec2(grainSize * 500.0);
      
      float noise = random(floor(grainUv));
      
      color.rgb += (noise - 0.5) * intensity;
      
      gl_FragColor = color;
    }
  `
}; 