export const MotionBlurShader = {
  uniforms: {
    'tDiffuse': { value: null },
    'velocityX': { value: 0.5 },
    'velocityY': { value: 0.5 },
    'samples': { value: 16 },
    'intensity': { value: 1.0 }
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform float velocityX;
    uniform float velocityY;
    uniform float samples;
    uniform float intensity;
    varying vec2 vUv;

    void main() {
      vec4 color = vec4(0.0);
      float total = 0.0;
      vec2 direction = vec2(velocityX, velocityY) * intensity * 0.01;
      
      for(float i = 0.0; i < 32.0; i++) {
        if(i >= samples) break;
        float weight = 1.0 - (i / samples);
        vec2 offset = direction * i;
        color += texture2D(tDiffuse, vUv + offset) * weight;
        total += weight;
      }
      
      gl_FragColor = color / total;
    }
  `
}; 