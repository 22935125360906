import React, { useState, useEffect } from 'react';

const Gallery = ({ images, startIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  useEffect(() => {
    // Prevent body scrolling when gallery is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        setCurrentIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
      } else if (e.key === 'ArrowRight') {
        setCurrentIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
      } else if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [images, onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-[9999] flex items-center justify-center">
      <button
        className="absolute top-0 right-0 p-8 text-white text-2xl hover:opacity-75 transition-opacity"
        onClick={onClose}
      >
        &times;
      </button>
      <button
        className="absolute left-0 h-[calc(100%-80px)] mt-20 px-12 text-white text-4xl hover:opacity-75 transition-opacity flex items-center justify-center group"
        onClick={() => setCurrentIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1))}
      >
        <span className="transform group-hover:-translate-x-2 transition-transform duration-300">&#8249;</span>
      </button>
      <img
        src={images[currentIndex]}
        alt={`Gallery ${currentIndex + 1}`}
        className="max-h-[90vh] max-w-[90vw] object-contain"
      />
      <button
        className="absolute right-0 h-[calc(100%-80px)] mt-20 px-12 text-white text-4xl hover:opacity-75 transition-opacity flex items-center justify-center group"
        onClick={() => setCurrentIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0))}
      >
        <span className="transform group-hover:translate-x-2 transition-transform duration-300">&#8250;</span>
      </button>
    </div>
  );
};

export default Gallery;
