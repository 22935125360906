import { createPortal } from 'react-dom';
import Gallery from './Gallery';

const GalleryPortal = ({ images, startIndex, onClose }) => {
  return createPortal(
    <Gallery images={images} startIndex={startIndex} onClose={onClose} />,
    document.body
  );
};

export default GalleryPortal; 