import React, { useState, useEffect } from 'react';

const ScrollIndicator = () => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const fadeOutPoint = 100;
      const newOpacity = Math.max(0, 1 - (scrollPosition / fadeOutPoint));
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div 
      className="absolute bottom-24 md:bottom-12 left-1/2 -translate-x-1/2 mix-blend-difference transition-opacity duration-200"
      style={{ opacity }}
    >
      <div className="w-5 h-8 border-2 border-white/30 rounded-full flex items-center justify-center">
        <div className="w-1.5 h-1.5 bg-white rounded-full animate-scroll-dot" />
      </div>
    </div>
  );
};

export default ScrollIndicator; 