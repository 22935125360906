import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { SiSubstack } from 'react-icons/si';

function Footer() {
  const iconContainerStyle = "flex items-center justify-center w-12 h-12 border border-[#333333] rounded-md hover:border-white transition-colors";
  const iconStyle = "text-[#CBCBCB] group-hover:text-white transition-colors";

  return (
    <footer className="flex flex-col gap-6 px-5 py-10 text-center @container" id="contact">
      <div className="flex flex-wrap justify-center gap-4">
        <a href="https://linkedin.com/in/dlsamson" target="_blank" rel="noopener noreferrer" className={`group ${iconContainerStyle}`}>
          <FaLinkedin size={20} className={iconStyle} />
        </a>
        <a href="mailto:daniel.l.samson@gmail.com" className={`group ${iconContainerStyle}`}>
          <MdEmail size={20} className={iconStyle} />
        </a>
        <a href="https://danielsamson.substack.com/" target="_blank" rel="noopener noreferrer" className={`group ${iconContainerStyle}`}>
          <SiSubstack size={20} className={iconStyle} />
        </a>
      </div>
      <p className="text-[#CBCBCB] text-base font-normal leading-normal">© {new Date().getFullYear()} Daniel Samson. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
