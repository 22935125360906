export const WaveDistortionShader = {
  uniforms: {
    'tDiffuse': { value: null },
    'time': { value: 0.0 },
    'amplitude': { value: 0.02 },
    'frequency': { value: 5.0 },
    'speed': { value: 1.0 }
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform float time;
    uniform float amplitude;
    uniform float frequency;
    uniform float speed;
    varying vec2 vUv;

    void main() {
      vec2 uv = vUv;
      
      // Add wave distortion
      uv.x += sin(uv.y * frequency + time * speed) * amplitude;
      uv.y += sin(uv.x * frequency * 0.5 + time * speed * 0.5) * amplitude;

      gl_FragColor = texture2D(tDiffuse, uv);
    }
  `
}; 