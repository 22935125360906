import * as THREE from 'three';

export class BlobAnimation {
  constructor(params) {
    const {
      blob,
      noise3D,
      animationSettingsRef,
      iorAnimationSettingsRef,
      material,
      timeRef,
      mouseVelocityRef,
      mouseDOFRef,
      postProcessingRef,
      qualitySettings
    } = params;

    this.blob = blob;
    this.noise3D = noise3D;
    this.animationSettingsRef = animationSettingsRef;
    this.iorAnimationSettingsRef = iorAnimationSettingsRef;
    this.material = material;
    this.timeRef = timeRef;
    this.mouseVelocityRef = mouseVelocityRef;
    this.mouseDOFRef = mouseDOFRef;
    this.postProcessingRef = postProcessingRef;
    this.vertex = new THREE.Vector3();
    
    // Motion blur velocity damping
    this.dampingFactor = 0.95;
    this.velocityThreshold = 0.001;
    this.qualitySettings = qualitySettings;
    this.frameCount = 0;
  }

  animate() {
    if (!this.blob) return;

    this.timeRef.current += this.animationSettingsRef.current.timeScale;
    const time = this.timeRef.current;

    this.animateIOR(time);
    this.animatePosition(time);
    this.animateRotation(time);
    this.animateMorphing(time);
    this.updateMotionEffects();
    this.updateShaderTime(time);
  }

  updateMotionEffects() {
    if (!this.mouseVelocityRef || !this.postProcessingRef?.current) return;

    // Check if velocity is above threshold
    if (Math.abs(this.mouseVelocityRef.current.x) > this.velocityThreshold || 
        Math.abs(this.mouseVelocityRef.current.y) > this.velocityThreshold) {
      
      // Update motion blur
      this.postProcessingRef.current.updateMotionBlur(
        this.mouseVelocityRef.current.x,
        this.mouseVelocityRef.current.y
      );

      // Dampen velocity
      this.mouseVelocityRef.current.x *= this.dampingFactor;
      this.mouseVelocityRef.current.y *= this.dampingFactor;
    } else {
      // Reset velocity and effects when below threshold
      this.mouseVelocityRef.current.x = 0;
      this.mouseVelocityRef.current.y = 0;
      if (this.postProcessingRef.current) {
        this.postProcessingRef.current.updateMotionBlur(0, 0);
      }
    }
  }

  updateShaderTime(time) {
    if (this.postProcessingRef?.current) {
      this.postProcessingRef.current.updateTime(time);
    }
  }

  animateIOR(time) {
    if (this.iorAnimationSettingsRef.current.enabled && this.material) {
      const midIOR = (this.iorAnimationSettingsRef.current.maxIOR + this.iorAnimationSettingsRef.current.minIOR) / 2;
      const iorRange = (this.iorAnimationSettingsRef.current.maxIOR - this.iorAnimationSettingsRef.current.minIOR) / 2;
      this.material.iridescenceIOR = midIOR + 
        Math.sin(time * this.iorAnimationSettingsRef.current.speed) * 
        iorRange * 
        this.iorAnimationSettingsRef.current.amplitude;
    }
  }

  animatePosition(time) {
    const heartbeat = this.calculateHeartbeat(time);
    
    this.blob.position.y = 2.5 + 
      Math.sin(time * 0.8) * this.animationSettingsRef.current.positionAmplitude + 
      Math.sin(time * 1.2) * (this.animationSettingsRef.current.positionAmplitude * 0.5);

    const baseScale = 1.0;
    const scaleWithHeartbeat = baseScale + heartbeat;
    this.blob.scale.set(scaleWithHeartbeat, scaleWithHeartbeat, scaleWithHeartbeat);
  }

  animateRotation(time) {
    this.blob.rotation.x = time * this.animationSettingsRef.current.rotationSpeed;
    this.blob.rotation.y = time * (this.animationSettingsRef.current.rotationSpeed * 0.6) + 
      Math.sin(time * 0.8) * (this.animationSettingsRef.current.positionAmplitude * 0.5);
  }

  animateMorphing(time) {
    const position = this.blob.geometry.attributes.position;
    const heartbeat = this.calculateHeartbeat(time);

    // Get stride from quality settings
    const stride = this.qualitySettings.morphingQuality.vertexStride;
    const normalUpdateInterval = this.qualitySettings.morphingQuality.normalUpdateInterval;

    for(let i = 0; i < position.count; i += stride) {
      this.vertex.fromBufferAttribute(position, i);
      this.vertex.normalize();

      const heartbeatInfluence = 1.0 + (heartbeat * (1.0 - Math.abs(this.vertex.y)));
      const noise = this.calculateNoise(this.vertex, time);
      
      const positionFactor = Math.abs(this.vertex.y) * this.animationSettingsRef.current.verticalBias;
      const deformation = (2.0 + (this.animationSettingsRef.current.deformationStrength * 
        noise * (1 + positionFactor))) * heartbeatInfluence;

      this.vertex.multiplyScalar(deformation);
      position.setXYZ(i, this.vertex.x, this.vertex.y, this.vertex.z);

      // Copy the same deformation to the skipped vertex on low quality
      if (stride > 1 && i + 1 < position.count) {
        position.setXYZ(i + 1, this.vertex.x, this.vertex.y, this.vertex.z);
      }
    }

    position.needsUpdate = true;
    
    // Update normals based on quality interval
    if (this.frameCount % normalUpdateInterval === 0) {
      this.blob.geometry.computeVertexNormals();
    }
    this.frameCount++;
  }

  calculateHeartbeat(time) {
    return Math.pow(
      Math.sin(time * this.animationSettingsRef.current.heartbeatSpeed * Math.PI) * 0.5 + 0.5,
      this.animationSettingsRef.current.heartbeatSharpness
    ) * this.animationSettingsRef.current.heartbeatStrength;
  }

  calculateNoise(vertex, time) {
    const noise1 = this.noise3D(
      vertex.x * this.animationSettingsRef.current.noiseScale1 + time * this.animationSettingsRef.current.timeScale1,
      vertex.y * this.animationSettingsRef.current.noiseScale1 + time * this.animationSettingsRef.current.timeScale1,
      vertex.z * this.animationSettingsRef.current.noiseScale1 + time * this.animationSettingsRef.current.timeScale1
    ) * this.animationSettingsRef.current.noiseInfluence1 * this.animationSettingsRef.current.noiseAmplitude;

    const noise2 = this.noise3D(
      vertex.x * this.animationSettingsRef.current.noiseScale2 + time * this.animationSettingsRef.current.timeScale2,
      vertex.y * this.animationSettingsRef.current.noiseScale2 + time * this.animationSettingsRef.current.timeScale2,
      vertex.z * this.animationSettingsRef.current.noiseScale2 + time * this.animationSettingsRef.current.timeScale2
    ) * this.animationSettingsRef.current.noiseInfluence2 * this.animationSettingsRef.current.noiseAmplitude;

    const noise3 = this.noise3D(
      vertex.x * this.animationSettingsRef.current.noiseScale3 + time * this.animationSettingsRef.current.timeScale3,
      vertex.y * this.animationSettingsRef.current.noiseScale3 + time * this.animationSettingsRef.current.timeScale3,
      vertex.z * this.animationSettingsRef.current.noiseScale3 + time * this.animationSettingsRef.current.timeScale3
    ) * this.animationSettingsRef.current.noiseInfluence3 * this.animationSettingsRef.current.noiseAmplitude;

    return (noise1 + noise2 + noise3) / 2.5;
  }
} 