import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { MotionBlurShader } from '../shaders/motion/MotionBlurShader';
import { DigitalGlitchShader } from '../shaders/glitch/DigitalGlitchShader';
import { WaveDistortionShader } from '../shaders/distortion/WaveDistortionShader';
import { FilmGrainShader } from '../shaders/film/FilmGrainShader';
import { FadeShader } from '../shaders/fade/FadeShader';
import { PostProcessingConfig } from './PostProcessingConfig';
import { PostProcessingPass } from './PostProcessingPass';

export class PostProcessingStack {
  constructor(renderer, scene, camera, settings, layerToggles, quality) {
    this.config = new PostProcessingConfig(settings, layerToggles, quality);
    this.passes = new Map();
    this.composer = this.createComposer(renderer);
    
    this.frameCount = 0;
    this.fadeInDuration = 4.0;
    this.fadeInStartTime = performance.now();
    this.isFadingIn = true;

    this.setupPasses(scene, camera);
    this.applyQualitySettings();
  }

  createComposer(renderer) {
    const { resolutionScale } = this.config.getQualitySettings(this.config.quality);
    renderer.setPixelRatio(window.devicePixelRatio * resolutionScale);
    
    const composer = new EffectComposer(renderer);
    composer.setPixelRatio(window.devicePixelRatio * resolutionScale);
    return composer;
  }

  setupPasses(scene, camera) {
    // Add render pass
    this.addPass('render', new RenderPass(scene, camera));

    if (this.config.layerToggles.bloom) {
      this.setupBloomPass();
    }
    if (this.config.layerToggles.rgbShift) {
      this.setupRGBShiftPass();
    }
    if (this.config.layerToggles.motionBlur) {
      this.setupMotionBlurPass();
    }
    if (this.config.layerToggles.glitch) {
      this.setupGlitchPass();
    }
    if (this.config.layerToggles.wave) {
      this.setupWavePass();
    }
    if (this.config.layerToggles.fade) {
      this.setupFadePass();
    }
    
    this.setupFilmGrainPass();
    this.reorderPasses(this.config.settings.effectOrder);
  }

  setupBloomPass() {
    const bloomSettings = this.config.settings.bloom;
    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      bloomSettings.strength,
      bloomSettings.radius,
      bloomSettings.threshold
    );
    this.addPass('bloom', bloomPass, bloomSettings);
  }

  setupRGBShiftPass() {
    const rgbShiftPass = new ShaderPass(RGBShiftShader);
    const settings = this.config.settings.rgbShift;
    rgbShiftPass.uniforms.amount.value = settings.baseAmount;
    this.addPass('rgbShift', rgbShiftPass, settings);
  }

  setupMotionBlurPass() {
    const motionBlurPass = new ShaderPass(MotionBlurShader);
    const settings = this.config.settings.motionBlur || {};
    motionBlurPass.uniforms.velocityX.value = 0;
    motionBlurPass.uniforms.velocityY.value = 0;
    motionBlurPass.uniforms.intensity.value = settings.intensity || 1.0;
    motionBlurPass.uniforms.samples.value = settings.samples || 16;
    this.addPass('motionBlur', motionBlurPass, settings);
  }

  setupGlitchPass() {
    const glitchPass = new ShaderPass(DigitalGlitchShader);
    const settings = this.config.settings.glitch || {};
    this.addPass('glitch', glitchPass, settings);
  }

  setupWavePass() {
    const wavePass = new ShaderPass(WaveDistortionShader);
    const settings = this.config.settings.wave || {};
    this.addPass('wave', wavePass, settings);
  }

  setupFadePass() {
    const fadePass = new ShaderPass(FadeShader);
    const settings = this.config.settings.fade || {};
    fadePass.uniforms.fadeAmount.value = 1.0;
    fadePass.uniforms.fadeColor.value.setRGB(0, 0, 0);
    this.addPass('fade', fadePass, settings);
  }

  setupFilmGrainPass() {
    const filmGrainPass = new ShaderPass(FilmGrainShader);
    const settings = this.config.settings.filmGrain || {};
    filmGrainPass.uniforms.intensity.value = settings.intensity || 0.15;
    filmGrainPass.uniforms.speed.value = settings.speed || 1.0;
    filmGrainPass.uniforms.grainSize.value = settings.grainSize || 1.0;
    this.addPass('filmGrain', filmGrainPass, settings);
  }

  reorderPasses(effectOrder) {
    // Keep only the render pass
    const renderPass = this.composer.passes[0];
    this.composer.passes.length = 1;

    // Sort passes by order
    const sortedPasses = Array.from(this.passes.entries())
      .filter(([name]) => name !== 'render')
      .sort(([nameA], [nameB]) => {
        const orderA = effectOrder[nameA] ?? this.config.defaultOrder[nameA] ?? 999;
        const orderB = effectOrder[nameB] ?? this.config.defaultOrder[nameB] ?? 999;
        return orderA - orderB;
      });

    // Add passes in sorted order
    sortedPasses.forEach(([_, pass]) => {
      if (pass.pass) {
        this.composer.addPass(pass.pass);
      }
    });

    // Ensure the last pass renders to screen
    this.composer.passes.forEach((pass, index) => {
      pass.renderToScreen = (index === this.composer.passes.length - 1);
    });
  }

  applyQualitySettings() {
    const { maxSamples } = this.config.getQualitySettings(this.config.quality);
    
    // Adjust samples for motion blur
    const motionBlurPass = this.passes.get('motionBlur');
    if (motionBlurPass?.pass.uniforms.samples) {
      motionBlurPass.pass.uniforms.samples.value = Math.min(
        motionBlurPass.pass.uniforms.samples.value,
        maxSamples
      );
    }
  }

  addPass(name, pass, config = {}) {
    const processedPass = new PostProcessingPass(pass, name, config);
    this.passes.set(name, processedPass);
    this.composer.addPass(pass);
    return processedPass;
  }

  updatePass(name, uniforms) {
    const pass = this.passes.get(name);
    if (pass) {
      pass.updateUniforms(uniforms);
    }
  }

  render(time, deltaTime) {
    // Update all passes
    this.passes.forEach(pass => pass.update(time, deltaTime));
    
    if (this.isFadingIn) {
      this.updateFadeIn();
    }
    
    this.composer.render();
  }

  updateMotionBlur(velocityX, velocityY) {
    const motionBlurPass = this.passes.get('motionBlur');
    if (motionBlurPass?.pass?.uniforms) {
      // Scale velocities by motion scale from settings
      const motionScale = this.config.settings.motionBlur?.motionScale ?? 1.0;
      const scaledVelocityX = velocityX * motionScale;
      const scaledVelocityY = velocityY * motionScale;

      // Apply velocities to motion blur uniforms
      motionBlurPass.updateUniforms({
        velocityX: scaledVelocityX,
        velocityY: scaledVelocityY
      });

      // Update RGB shift based on motion if enabled
      const rgbShiftPass = this.passes.get('rgbShift');
      if (rgbShiftPass?.pass?.uniforms && this.config.settings.rgbShift) {
        const totalVelocity = Math.sqrt(scaledVelocityX * scaledVelocityX + scaledVelocityY * scaledVelocityY);
        const baseAmount = this.config.settings.rgbShift.baseAmount;
        const motionMultiplier = this.config.settings.rgbShift.motionMultiplier;
        
        rgbShiftPass.updateUniforms({
          amount: baseAmount + (totalVelocity * motionMultiplier * baseAmount)
        });
      }
    }
  }

  updateDOF(focus) {
    const dofPass = this.passes.get('dof');
    if (dofPass?.pass?.uniforms) {
      dofPass.updateUniforms({ focus });
    }
  }

  setupDOFPass() {
    const dofPass = new BokehPass(this.scene, this.camera, {
      focus: 1.0,
      aperture: 0.025,
      maxblur: 1.0,
    });
    const settings = this.config.settings.dof || {};
    this.addPass('dof', dofPass, settings);
  }

  updateTime(time) {
    this.passes.forEach(pass => {
      if (pass.pass?.uniforms?.time) {
        pass.updateUniforms({ time });
      }
    });
  }

  updateSize(width, height) {
    this.composer.setSize(width, height);
    
    // Update any passes that need resize handling
    this.passes.forEach(pass => {
      if (pass.resize) {
        pass.resize(width, height);
      }
    });
  }

  updateFadeIn() {
    if (!this.isFadingIn) return;
    
    const elapsed = (performance.now() - this.fadeInStartTime) / 1000;
    const progress = Math.max(0, Math.min(1, elapsed / this.fadeInDuration));
    
    // Ease out cubic
    const eased = 1 - Math.pow(1 - progress, 3);
    
    const fadePass = this.passes.get('fade');
    if (fadePass) {
      fadePass.updateUniforms({
        fadeAmount: 1 - eased
      });
    }
    
    if (progress >= 1) {
      this.isFadingIn = false;
    }
  }

  dispose() {
    this.passes.forEach(pass => pass.dispose());
    this.composer.dispose();
    this.passes.clear();
  }

  updateSettings(newSettings) {

    // Update config with new settings
    this.config.settings = this.config.validateSettings(newSettings);

    // Update each pass with new settings
    if (newSettings.bloom && this.passes.get('bloom')) {
      const bloomPass = this.passes.get('bloom');
      bloomPass.updateUniforms({
        strength: newSettings.bloom.strength,
        radius: newSettings.bloom.radius,
        threshold: newSettings.bloom.threshold
      });
    }

    if (newSettings.rgbShift && this.passes.get('rgbShift')) {
      const rgbShiftPass = this.passes.get('rgbShift');
      rgbShiftPass.updateUniforms({
        amount: newSettings.rgbShift.baseAmount
      });
    }

    if (newSettings.motionBlur && this.passes.get('motionBlur')) {
      const motionBlurPass = this.passes.get('motionBlur');
      motionBlurPass.updateUniforms({
        intensity: newSettings.motionBlur.intensity,
        samples: newSettings.motionBlur.samples
      });
    }

    if (newSettings.glitch && this.passes.get('glitch')) {
      const glitchPass = this.passes.get('glitch');

      glitchPass.updateUniforms({
        // Timing
        speed: newSettings.glitch.speed,
        glitchInterval: newSettings.glitch.glitchInterval,
        glitchDuration: newSettings.glitch.glitchDuration,
        burstSpeed: newSettings.glitch.burstSpeed,
        
        // Block glitch
        blockAmount: newSettings.glitch.blockAmount,
        blockSize: newSettings.glitch.blockSize,
        blockOffset: newSettings.glitch.blockOffset,
        blockThreshold: newSettings.glitch.blockThreshold,
        blockAlpha: newSettings.glitch.blockAlpha,
        rgbOffset: newSettings.glitch.rgbOffset,
        
        // Scanlines
        scanlineAmount: newSettings.glitch.scanlineAmount,
        scanlineSize: newSettings.glitch.scanlineSize,
        distortionAmount: newSettings.glitch.distortionAmount
     });
    }

    if (newSettings.wave && this.passes.get('wave')) {
      const wavePass = this.passes.get('wave');
      Object.entries(newSettings.wave).forEach(([key, value]) => {
        wavePass.updateUniforms({ [key]: value });
      });
    }

    if (newSettings.filmGrain && this.passes.get('filmGrain')) {
      const filmGrainPass = this.passes.get('filmGrain');
      filmGrainPass.updateUniforms({
        intensity: newSettings.filmGrain.intensity,
        speed: newSettings.filmGrain.speed,
        grainSize: newSettings.filmGrain.grainSize
      });
    }

    if (newSettings.dof && this.passes.get('dof')) {
      const dofPass = this.passes.get('dof');
      dofPass.updateUniforms({
        focus: newSettings.dof.focus,
        aperture: newSettings.dof.aperture,
        maxblur: newSettings.dof.maxblur
      });
    }

    // Update pass order if provided
    if (newSettings.effectOrder) {
      this.reorderPasses(newSettings.effectOrder);
    }
  }

  getSettings() {
    return {
      bloom: this.config.settings.bloom,
      rgbShift: this.config.settings.rgbShift,
      motionBlur: this.config.settings.motionBlur,
      glitch: this.config.settings.glitch,
      wave: this.config.settings.wave,
      filmGrain: this.config.settings.filmGrain,
      dof: this.config.settings.dof,
      effectOrder: this.config.settings.effectOrder
    };
  }

  toggleEffect(effectName, enabled) {
    const pass = this.passes.get(effectName);
    if (pass) {
      pass.pass.enabled = enabled;
      if (this.config.layerToggles) {
        this.config.layerToggles[effectName] = enabled;
      }
    }
  }
} 
