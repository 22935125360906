import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import * as THREE from 'three';

export class BlobGUI {
  constructor(params) {
    this.material = params.material;
    this.ambientLight = params.ambientLight;
    this.directionalLight = params.directionalLight;
    this.pointLight = params.pointLight;
    this.postProcessingRef = params.postProcessingRef;
    this.animationSettingsRef = params.animationSettingsRef;
    this.effectOrderRef = params.effectOrderRef;
    this.layerTogglesRef = params.layerTogglesRef;
    this.iorAnimationSettingsRef = params.iorAnimationSettingsRef;
    this.mouseDOFRef = params.mouseDOFRef;
    this.settingsRef = params.settingsRef;
    this.blob = params.blob;
    this.automatedMovementRef = params.automatedMovementRef;
    this.stars = params.starsRef?.current;
    this.fpsCounter = { value: 0 };

    this.gui = new GUI();
    
    this.updateAllControllers = this.updateAllControllers.bind(this);
    
    const urlParams = new URLSearchParams(window.location.search);
    const showControls = urlParams.get('debug') === 'true';

    if (!showControls) {
      this.gui.domElement.style.display = 'none';
    }

    const performanceFolder = this.gui.addFolder('Performance');
    performanceFolder.add(this.fpsCounter, 'value')
      .name('FPS')
      .listen()
      .disable();
    performanceFolder.open();

    this.setupSaveLoadControls(this.settingsRef);
    this.setupMaterialControls(this.material);
    this.setupLightingControls(this.ambientLight, this.directionalLight, this.pointLight);
    this.setupAnimationControls(this.animationSettingsRef);
    this.setupNoiseControls(this.animationSettingsRef);
    this.setupHeartbeatControls(this.animationSettingsRef);
    this.setupPostProcessingControls({
      postProcessingRef: this.postProcessingRef,
      effectOrderRef: this.effectOrderRef,
      layerTogglesRef: this.layerTogglesRef,
      mouseDOFRef: this.mouseDOFRef
    });
    this.setupTransformControls(this.blob);
    this.setupAutomatedMovementControls(this.automatedMovementRef);

    if (this.stars) {
        this.setupStarsControls(this.stars);
    }
  }

  updateAllControllers() {
    const updateFolder = (folder) => {
      // Update controllers in this folder
      folder.controllers.forEach(controller => {
        controller.updateDisplay();
      });

      // Recursively update all subfolders
      Object.values(folder.folders).forEach(subFolder => {
        updateFolder(subFolder);
      });
    };

    // Update all root folders
    Object.values(this.gui.folders).forEach(folder => {
      updateFolder(folder);
    });
  }

  setupSaveLoadControls(settingsRef) {
    const getColorValue = (color) => {
      if (!color) return null;
      if (Array.isArray(color)) return color;
      if (typeof color.r === 'number') return [color.r, color.g, color.b];
      if (typeof color === 'number') {
        const hex = color;
        return [
          ((hex >> 16) & 255) / 255,
          ((hex >> 8) & 255) / 255,
          (hex & 255) / 255
        ];
      }
      return null;
    };

    const createSettingsSnapshot = () => ({
      material: {
        color: getColorValue(settingsRef.current.material?.color),
        emissive: getColorValue(settingsRef.current.material?.emissive),
        metalness: settingsRef.current.material?.metalness,
        roughness: settingsRef.current.material?.roughness,
        emissiveIntensity: settingsRef.current.material?.emissiveIntensity,
        envMapIntensity: settingsRef.current.material?.envMapIntensity,
        clearcoat: settingsRef.current.material?.clearcoat,
        clearcoatRoughness: settingsRef.current.material?.clearcoatRoughness,
        sheen: settingsRef.current.material?.sheen,
        sheenRoughness: settingsRef.current.material?.sheenRoughness,
        sheenColor: getColorValue(settingsRef.current.material?.sheenColor),
        iridescence: settingsRef.current.material?.iridescence,
        iridescenceIOR: settingsRef.current.material?.iridescenceIOR,
        transmission: settingsRef.current.material?.transmission,
        ior: settingsRef.current.material?.ior,
        thickness: settingsRef.current.material?.thickness,
      },
      lighting: {
        ambient: {
          intensity: this.ambientLight.intensity,
          color: getColorValue(this.ambientLight.color),
          visible: this.ambientLight.visible
        },
        directional: {
          intensity: this.directionalLight.intensity,
          color: getColorValue(this.directionalLight.color),
          visible: this.directionalLight.visible,
          position: {
            x: this.directionalLight.position.x,
            y: this.directionalLight.position.y,
            z: this.directionalLight.position.z
          }
        },
        point: {
          intensity: this.pointLight.intensity,
          color: getColorValue(this.pointLight.color),
          visible: this.pointLight.visible,
          position: {
            x: this.pointLight.position.x,
            y: this.pointLight.position.y,
            z: this.pointLight.position.z
          },
          distance: this.pointLight.distance,
          decay: this.pointLight.decay
        }
      },
      stars: {
        count: this.stars.settings.count,
        minSize: this.stars.settings.minSize,
        maxSize: this.stars.settings.maxSize,
        spread: this.stars.settings.spread,
        twinkleSpeed: this.stars.settings.twinkleSpeed,
        depth: this.stars.settings.depth
      },
      animation: settingsRef.current.animation,
      noise: settingsRef.current.noise,
      postProcessing: settingsRef.current.postProcessing,
      effectOrder: settingsRef.current.effectOrder,
      layerToggles: settingsRef.current.layerToggles,
      iorAnimation: settingsRef.current.iorAnimation,
      automatedMovement: settingsRef.current.automatedMovement,
    });

    const randomizers = {
      randomizeMaterial: () => {
        Object.assign(this.material, {
          color: new THREE.Color(Math.random(), Math.random(), Math.random()),
          emissive: new THREE.Color(Math.random(), Math.random(), Math.random()),
          metalness: Math.random(),
          roughness: Math.random(),
          emissiveIntensity: Math.random() * 2,
          envMapIntensity: Math.random() * 3,
          clearcoat: Math.random(),
          clearcoatRoughness: Math.random(),
          sheen: Math.random(),
          sheenRoughness: Math.random(),
          sheenColor: new THREE.Color(Math.random(), Math.random(), Math.random()),
          iridescence: Math.random(),
          iridescenceIOR: 1 + Math.random() * 1.333,
          transmission: Math.random(),
          ior: 1 + Math.random() * 1.333,
          thickness: Math.random() * 5,
        });

        settingsRef.current.material = {
          color: [this.material.color.r, this.material.color.g, this.material.color.b],
          emissive: [this.material.emissive.r, this.material.emissive.g, this.material.emissive.b],
          metalness: this.material.metalness,
          roughness: this.material.roughness,
          emissiveIntensity: this.material.emissiveIntensity,
          envMapIntensity: this.material.envMapIntensity,
          clearcoat: this.material.clearcoat,
          clearcoatRoughness: this.material.clearcoatRoughness,
          sheen: this.material.sheen,
          sheenRoughness: this.material.sheenRoughness,
          sheenColor: [this.material.sheenColor.r, this.material.sheenColor.g, this.material.sheenColor.b],
          iridescence: this.material.iridescence,
          iridescenceIOR: this.material.iridescenceIOR,
          transmission: this.material.transmission,
          ior: this.material.ior,
          thickness: this.material.thickness,
        };
        
        this.updateAllControllers();
      },
      
      randomizeLighting: () => {
        // Update actual lights
        this.ambientLight.intensity = Math.random() * 5;
        this.ambientLight.color.setHSL(Math.random(), 0.5, 0.5);

        this.directionalLight.intensity = Math.random() * 5;
        this.directionalLight.color.setHSL(Math.random(), 0.5, 0.5);
        this.directionalLight.position.set(
            (Math.random() - 0.5) * 10,
            1 + Math.random() * 4,
            -2 - Math.random() * 4
        );

        this.pointLight.intensity = Math.random() * 5;
        this.pointLight.color.setHSL(Math.random(), 0.5 + Math.random() * 0.5, 0.5 + Math.random() * 0.5);
        this.pointLight.position.set(
            (Math.random() - 0.5) * 10,
            1 + Math.random() * 4,
            -2 - Math.random() * 4
        );
        this.pointLight.distance = 5 + Math.random() * 15;
        this.pointLight.decay = 1 + Math.random();

        // Update settings ref
        settingsRef.current.lighting = {
            ambient: {
                intensity: this.ambientLight.intensity,
                color: [this.ambientLight.color.r, this.ambientLight.color.g, this.ambientLight.color.b],
                visible: this.ambientLight.visible
            },
            directional: {
                intensity: this.directionalLight.intensity,
                color: [this.directionalLight.color.r, this.directionalLight.color.g, this.directionalLight.color.b],
                visible: this.directionalLight.visible,
                position: {
                    x: this.directionalLight.position.x,
                    y: this.directionalLight.position.y,
                    z: this.directionalLight.position.z
                }
            },
            point: {
                intensity: this.pointLight.intensity,
                color: [this.pointLight.color.r, this.pointLight.color.g, this.pointLight.color.b],
                visible: this.pointLight.visible,
                position: {
                    x: this.pointLight.position.x,
                    y: this.pointLight.position.y,
                    z: this.pointLight.position.z
                },
                distance: this.pointLight.distance,
                decay: this.pointLight.decay
            }
        };

        // Update all GUI controllers
        this.updateAllControllers();
      },

      randomizeAnimation: () => {
        const newAnimation = {
          timeScale: 0.001 + Math.random() * 0.004,
          positionAmplitude: Math.random(),
          rotationSpeed: Math.random() * 2,
          heartbeat: {
            speed: Math.random() * 3,
            strength: Math.random(),
            sharpness: 0.1 + Math.random() * 4.9
          }
        };

        // Update both the ref and the settings
        Object.assign(this.animationSettingsRef.current, newAnimation);
        settingsRef.current.animation = newAnimation;
        
        this.updateAllControllers();
      },

      randomizeNoise: () => {
        const newNoise = {
          noiseScale1: Math.random() * 10,
          noiseScale2: Math.random() * 20,
          noiseScale3: Math.random() * 5,
          noiseInfluence1: Math.random() * 2,
          noiseInfluence2: Math.random() * 2,
          noiseInfluence3: Math.random() * 2,
          deformationStrength: Math.random(),
          timeScale1: 0.5 + Math.random() * 1.5,
          timeScale2: 0.3 + Math.random() * 1.2,
          timeScale3: 0.2 + Math.random() * 0.8,
          verticalBias: Math.random(),
          noiseAmplitude: Math.random() * 2
        };

        // Update both the ref and the settings
        Object.assign(this.animationSettingsRef.current, newNoise);
        settingsRef.current.noise = newNoise;
        
        this.updateAllControllers();
      },

      randomizePostProcessing: () => {
        if (!this.postProcessing) return;

        const currentSettings = this.postProcessing.getSettings();
        const newSettings = {
          ...currentSettings,
          rgbShift: {
            ...currentSettings.rgbShift,
            baseAmount: Math.random() * 0.01,
            motionMultiplier: Math.random() * 2.0
          },
          bloom: {
            ...currentSettings.bloom,
            strength: Math.random() * 3.0,
            radius: Math.random() * 1.5,
            threshold: Math.random() * 0.5
          },
          // Add other effects you want to randomize
        };

        this.postProcessing.updateSettings(newSettings);
      },

      randomizeAutomatedMovement: () => {
        // Create new settings
        const newMovement = {
            enabled: true,
            interval: 1000 + Math.random() * 9000,
            minDuration: 100 + Math.random() * 900,
            maxDuration: 1000 + Math.random() * 1000,
            minVelocity: -2 * Math.random(),
            maxVelocity: 2 * Math.random(),
            movementCount: 2 + Math.floor(Math.random() * 14)
        };

        // Update both refs
        Object.assign(this.automatedMovementRef.current, newMovement);
        settingsRef.current.automatedMovement = newMovement;
        
        this.updateAllControllers();
      },

      randomizeAll: () => {
        // Store all folders before randomization
        const folders = Object.values(this.gui.folders);
        
        // Perform all randomizations
        randomizers.randomizeMaterial.call(this);
        randomizers.randomizeLighting.call(this);
        randomizers.randomizeAnimation.call(this);
        randomizers.randomizeNoise.call(this);
        randomizers.randomizePostProcessing.call(this);
        randomizers.randomizeAutomatedMovement.call(this);

        // Update all controllers in all folders and subfolders
        folders.forEach(folder => {
            folder.controllers.forEach(controller => controller.updateDisplay());
            Object.values(folder.folders).forEach(subFolder => {
                subFolder.controllers.forEach(controller => controller.updateDisplay());
            });
        });
      }
    };

    this.randomizers = Object.keys(randomizers).reduce((acc, key) => {
      acc[key] = randomizers[key].bind(this);
      return acc;
    }, {});

    const saveLoadControls = {
      save: () => {
        const settingsToSave = createSettingsSnapshot();
        localStorage.setItem('blobSettings', JSON.stringify(settingsToSave));
        alert('Settings saved to localStorage!');
      },
      load: () => {
        const savedSettings = localStorage.getItem('blobSettings');
        if (savedSettings) {
          try {
            const parsedSettings = JSON.parse(savedSettings);
            settingsRef.current = parsedSettings;
            alert('Settings loaded from localStorage!');
          } catch (error) {
            alert('Error loading settings: ' + error.message);
          }
        }
      },
      reset: () => {
        settingsRef.current = { ...settingsRef.current };
        alert('Settings reset to default!');
      },
      downloadJSON: () => {
        const settingsToSave = createSettingsSnapshot();
        const dataStr = JSON.stringify(settingsToSave, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        const exportName = `blob-settings-${new Date().toISOString()}.json`;
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportName);
        linkElement.click();
      },
      uploadJSON: () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        
        input.onchange = e => {
          const file = e.target.files[0];
          const reader = new FileReader();
          
          reader.onload = readerEvent => {
            try {
              const content = readerEvent.target.result;
              const parsedSettings = JSON.parse(content);
              settingsRef.current = parsedSettings;
              alert('Settings loaded from file!');
            } catch (error) {
              alert('Error loading settings file: ' + error.message);
            }
          };

          reader.readAsText(file);
        };
        
        input.click();
      },
      randomizeAll: () => this.randomizers.randomizeAll(),
      randomizeMaterial: () => this.randomizers.randomizeMaterial(),
      randomizeLighting: () => this.randomizers.randomizeLighting(),
      randomizeAnimation: () => this.randomizers.randomizeAnimation(),
      randomizeNoise: () => this.randomizers.randomizeNoise(),
      randomizePostProcessing: () => this.randomizers.randomizePostProcessing(),
      randomizeAutomatedMovement: () => this.randomizers.randomizeAutomatedMovement()
    };

    const saveLoadFolder = this.gui.addFolder('Save/Load');
    
    const randomizeFolder = saveLoadFolder.addFolder('🎲 Randomize');
    randomizeFolder.add(saveLoadControls, 'randomizeAll').name('All Settings');
    randomizeFolder.add(saveLoadControls, 'randomizeMaterial').name('Material');
    randomizeFolder.add(saveLoadControls, 'randomizeLighting').name('Lighting');
    randomizeFolder.add(saveLoadControls, 'randomizeAnimation').name('Animation');
    randomizeFolder.add(saveLoadControls, 'randomizeNoise').name('Noise');
    randomizeFolder.add(saveLoadControls, 'randomizePostProcessing').name('Post-Processing');
    randomizeFolder.add(saveLoadControls, 'randomizeAutomatedMovement').name('Auto Movement');

    saveLoadFolder.add(saveLoadControls, 'save').name('Save to LocalStorage');
    saveLoadFolder.add(saveLoadControls, 'load').name('Load from LocalStorage');
    saveLoadFolder.add(saveLoadControls, 'reset').name('Reset to Default');
    saveLoadFolder.add(saveLoadControls, 'downloadJSON').name('Download Settings');
    saveLoadFolder.add(saveLoadControls, 'uploadJSON').name('Upload Settings');
  }

  setupMaterialControls(material) {
    const materialFolder = this.gui.addFolder('Material');
    
    materialFolder.add({
      randomize: () => {
        material.metalness = Math.random();
        material.roughness = Math.random();
        material.emissiveIntensity = Math.random() * 2;
        material.envMapIntensity = Math.random() * 3;
        material.clearcoat = Math.random();
        material.clearcoatRoughness = Math.random();
        material.sheen = Math.random();
        material.sheenRoughness = Math.random();
        material.sheenColor.setRGB(Math.random(), Math.random(), Math.random());
        material.iridescence = Math.random();
        material.iridescenceIOR = 1 + Math.random() * 1.333;
        material.transmission = Math.random();
        material.ior = 1 + Math.random() * 1.333;
        material.thickness = Math.random() * 5;
        materialFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    materialFolder.addColor(material, 'color').name('Base Color');
    materialFolder.addColor(material, 'emissive').name('Glow Color');
    materialFolder.add(material, 'metalness', 0, 1).name('Metalness');
    materialFolder.add(material, 'roughness', 0, 1).name('Roughness');
    materialFolder.add(material, 'emissiveIntensity', 0, 2).name('Glow Strength');
    materialFolder.add(material, 'envMapIntensity', 0, 3).name('Env Intensity');

    const clearcoatFolder = materialFolder.addFolder('Clearcoat');
    clearcoatFolder.add(material, 'clearcoat', 0, 1).name('Amount');
    clearcoatFolder.add(material, 'clearcoatRoughness', 0, 1).name('Roughness');

    const sheenFolder = materialFolder.addFolder('Sheen');
    sheenFolder.add(material, 'sheen', 0, 1).name('Amount');
    sheenFolder.add(material, 'sheenRoughness', 0, 1).name('Roughness');
    sheenFolder.addColor(material, 'sheenColor').name('Color');

    const iridescenceFolder = materialFolder.addFolder('Iridescence');
    iridescenceFolder.add(material, 'iridescence', 0, 1).name('Amount');
    iridescenceFolder.add(material, 'iridescenceIOR', 1, 2.333).name('IOR');

    const transmissionFolder = materialFolder.addFolder('Transmission');
    transmissionFolder.add(material, 'transmission', 0, 1).name('Amount');
    transmissionFolder.add(material, 'ior', 1, 2.333).name('IOR');
    transmissionFolder.add(material, 'thickness', 0, 5).name('Thickness');

    clearcoatFolder.add({
      randomize: () => {
        material.clearcoat = Math.random();
        material.clearcoatRoughness = Math.random();
        clearcoatFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    sheenFolder.add({
      randomize: () => {
        material.sheen = Math.random();
        material.sheenRoughness = Math.random();
        material.sheenColor.setRGB(Math.random(), Math.random(), Math.random());
        sheenFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    iridescenceFolder.add({
      randomize: () => {
        material.iridescence = Math.random();
        material.iridescenceIOR = 1 + Math.random() * 1.333;
        iridescenceFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    transmissionFolder.add({
      randomize: () => {
        material.transmission = Math.random();
        material.ior = 1 + Math.random() * 1.333;
        material.thickness = Math.random() * 5;
        transmissionFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');
  }

  setupLightingControls(ambientLight, directionalLight, pointLight) {
    const lightingFolder = this.gui.addFolder('Lighting');
    
    // Create subfolders first
    const ambientFolder = lightingFolder.addFolder('Ambient Light');
    const directionalFolder = lightingFolder.addFolder('Directional Light');
    const pointFolder = lightingFolder.addFolder('Point Light');
    
    lightingFolder.add({
      randomize: () => {
        // Ambient Light
        ambientLight.intensity = Math.random() * 5;
        ambientLight.color.setHSL(Math.random(), 0.5, 0.5);

        // Directional Light
        directionalLight.intensity = Math.random() * 5;
        directionalLight.color.setHSL(Math.random(), 0.5, 0.5);
        directionalLight.position.set(
          (Math.random() - 0.5) * 10,
          1 + Math.random() * 4,
          -2 - Math.random() * 4
        );

        // Point Light
        pointLight.intensity = Math.random() * 5;
        pointLight.color.setHSL(Math.random(), 0.5 + Math.random() * 0.5, 0.5 + Math.random() * 0.5);
        pointLight.position.set(
          (Math.random() - 0.5) * 10,
          1 + Math.random() * 4,
          -2 - Math.random() * 4
        );
        pointLight.distance = 5 + Math.random() * 15;
        pointLight.decay = 1 + Math.random();

        // Update all subfolder controllers
        ambientFolder.controllers.forEach(controller => controller.updateDisplay());
        directionalFolder.controllers.forEach(controller => controller.updateDisplay());
        pointFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    // Ambient Light controls
    ambientFolder.add(ambientLight, 'visible').name('Enabled');
    ambientFolder.add(ambientLight, 'intensity', 0, 5).name('Intensity');
    ambientFolder.addColor(ambientLight, 'color').name('Color');

    // Directional Light controls
    directionalFolder.add(directionalLight, 'visible').name('Enabled');
    directionalFolder.add(directionalLight, 'intensity', 0, 5).name('Intensity');
    directionalFolder.addColor(directionalLight, 'color').name('Color');
    directionalFolder.add(directionalLight.position, 'x', -10, 10).name('X Position');
    directionalFolder.add(directionalLight.position, 'y', -10, 10).name('Y Position');
    directionalFolder.add(directionalLight.position, 'z', -10, 10).name('Z Position');

    // Point Light controls
    pointFolder.add(pointLight, 'visible').name('Enabled');
    pointFolder.add(pointLight, 'intensity', 0, 5).name('Intensity');
    pointFolder.addColor(pointLight, 'color').name('Color');
    pointFolder.add(pointLight.position, 'x', -10, 10).name('X Position');
    pointFolder.add(pointLight.position, 'y', -10, 10).name('Y Position');
    pointFolder.add(pointLight.position, 'z', -10, 10).name('Z Position');
    pointFolder.add(pointLight, 'distance', 0, 20).name('Distance');
    pointFolder.add(pointLight, 'decay', 0, 2).name('Decay');

    // Individual randomize buttons
    ambientFolder.add({
      randomize: () => {
        ambientLight.intensity = Math.random() * 5;
        ambientLight.color.setHSL(Math.random(), 0.5, 0.5);
        ambientFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    directionalFolder.add({
      randomize: () => {
        directionalLight.intensity = Math.random() * 5;
        directionalLight.color.setHSL(Math.random(), 0.5, 0.5);
        directionalLight.position.set(
          (Math.random() - 0.5) * 10,
          1 + Math.random() * 4,
          -2 - Math.random() * 4
        );
        directionalFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    pointFolder.add({
      randomize: () => {
        pointLight.intensity = Math.random() * 5;
        pointLight.color.setHSL(Math.random(), 0.5 + Math.random() * 0.5, 0.5 + Math.random() * 0.5);
        pointLight.position.set(
          (Math.random() - 0.5) * 10,
          1 + Math.random() * 4,
          -2 - Math.random() * 4
        );
        pointLight.distance = 5 + Math.random() * 15;
        pointLight.decay = 1 + Math.random();
        pointFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');
  }

  setupAnimationControls(animationSettingsRef) {
    const animationFolder = this.gui.addFolder('Animation');
    animationFolder.add({
      randomize: () => {
        animationSettingsRef.current.timeScale = 0.001 + Math.random() * 0.049;
        animationSettingsRef.current.positionAmplitude = Math.random();
        animationSettingsRef.current.rotationSpeed = Math.random() * 2;
        animationFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    animationFolder.add(animationSettingsRef.current, 'timeScale', 0.001, 0.05).name('Time Scale');
    animationFolder.add(animationSettingsRef.current, 'positionAmplitude', 0, 1).name('Float Amount');
    animationFolder.add(animationSettingsRef.current, 'rotationSpeed', 0, 2).name('Rotation Speed');
  }

  setupNoiseControls(animationSettingsRef) {
    const noiseFolder = this.gui.addFolder('Noise');
    noiseFolder.add(animationSettingsRef.current, 'noiseScale1', 0, 10).name('Scale 1');
    noiseFolder.add(animationSettingsRef.current, 'noiseScale2', 0, 20).name('Scale 2');
    noiseFolder.add(animationSettingsRef.current, 'noiseScale3', 0, 5).name('Scale 3');
    noiseFolder.add(animationSettingsRef.current, 'noiseInfluence1', 0, 2).name('Influence 1');
    noiseFolder.add(animationSettingsRef.current, 'noiseInfluence2', 0, 2).name('Influence 2');
    noiseFolder.add(animationSettingsRef.current, 'noiseInfluence3', 0, 2).name('Influence 3');
    noiseFolder.add(animationSettingsRef.current, 'deformationStrength', 0, 1).name('Strength');
  }

  setupHeartbeatControls(animationSettingsRef) {
    const heartbeatFolder = this.gui.addFolder('Heartbeat');
    
    // Add randomize button
    heartbeatFolder.add({
      randomize: () => {
        animationSettingsRef.current.heartbeatSpeed = 0.1 + Math.random() * 2.9;
        animationSettingsRef.current.heartbeatStrength = Math.random();
        animationSettingsRef.current.heartbeatSharpness = 0.1 + Math.random() * 4.9;
        heartbeatFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');

    heartbeatFolder.add(animationSettingsRef.current, 'heartbeatSpeed', 0.1, 3.0).name('Beat Speed');
    heartbeatFolder.add(animationSettingsRef.current, 'heartbeatStrength', 0, 1.0).name('Beat Strength');
    heartbeatFolder.add(animationSettingsRef.current, 'heartbeatSharpness', 0.1, 5.0).name('Beat Sharpness');
  }

  setupPostProcessingControls(params) {
    const { postProcessingRef, effectOrderRef, layerTogglesRef, mouseDOFRef } = params;

    if (!postProcessingRef?.current?.passes) {
      console.warn('Post-processing stack not ready');
      return;
    }

    const postProcessingFolder = this.gui.addFolder('Post Processing');

    const effectsFolder = postProcessingFolder.addFolder('Effect Layers');
    Object.keys(layerTogglesRef.current).forEach(effect => {
      effectsFolder.add(layerTogglesRef.current, effect)
        .name(effect.charAt(0).toUpperCase() + effect.slice(1))
        .onChange(value => {
          if (postProcessingRef.current?.passes[effect]) {
            postProcessingRef.current.passes[effect].enabled = value;
          }
        });
    });

    if (postProcessingRef.current.passes.bloom) {
      const bloomFolder = postProcessingFolder.addFolder('Bloom');
      const bloom = postProcessingRef.current.passes.bloom;
      bloomFolder.add(bloom, 'strength', 0, 3).name('Strength');
      bloomFolder.add(bloom, 'radius', 0, 1).name('Radius');
      bloomFolder.add(bloom, 'threshold', 0, 1).name('Threshold');

      bloomFolder.add({
        randomize: () => {
          bloom.strength = Math.random() * 3;
          bloom.radius = Math.random();
          bloom.threshold = Math.random();
          bloomFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');
    }

    if (postProcessingRef.current.passes.rgbShift?.uniforms?.amount) {
      const rgbShiftFolder = postProcessingFolder.addFolder('RGB Shift');
      const rgbShift = postProcessingRef.current.passes.rgbShift;
      rgbShiftFolder.add(rgbShift.uniforms.amount, 'value', 0, 0.01)
        .name('Base Amount')
        .onChange(value => {
          rgbShift.uniforms.amount.value = value;
          if (postProcessingRef.current.settings.rgbShift) {
            postProcessingRef.current.settings.rgbShift.baseAmount = value;
          }
        });

      if (postProcessingRef.current.settings.rgbShift) {
        rgbShiftFolder.add(postProcessingRef.current.settings.rgbShift, 'motionMultiplier', 0, 10)
          .name('Motion Multi')
          .onChange(value => {
            postProcessingRef.current.settings.rgbShift.motionMultiplier = value;
          });
      }

      rgbShiftFolder.add({
        randomize: () => {
          rgbShift.uniforms.amount.value = Math.random() * 0.005;
          if (postProcessingRef.current.settings.rgbShift) {
            postProcessingRef.current.settings.rgbShift.baseAmount = rgbShift.uniforms.amount.value;
            postProcessingRef.current.settings.rgbShift.motionMultiplier = Math.random() * 15;
          }
          rgbShiftFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');
    }

    if (postProcessingRef.current.passes.motionBlur?.uniforms) {
      const motionBlurFolder = postProcessingFolder.addFolder('Motion Blur');
      const motionBlur = postProcessingRef.current.passes.motionBlur.uniforms;
      if (motionBlur.intensity) {
        motionBlurFolder.add(motionBlur.intensity, 'value', 0, 5).name('Intensity');
      }
      if (motionBlur.samples) {
        motionBlurFolder.add(motionBlur.samples, 'value', 1, 32, 1).name('Samples');
      }
      if (postProcessingRef.current.settings.motionBlur) {
        motionBlurFolder.add(postProcessingRef.current.settings.motionBlur, 'motionScale', 0, 2)
          .name('Motion Scale')
          .onChange(value => {
            postProcessingRef.current.settings.motionBlur.motionScale = value;
          });
        motionBlurFolder.add(postProcessingRef.current.settings.motionBlur, 'dampingFactor', 0.9, 0.99)
          .name('Damping')
          .onChange(value => {
            postProcessingRef.current.settings.motionBlur.dampingFactor = value;
          });
      }

      motionBlurFolder.add({
        randomize: () => {
          motionBlur.intensity.value = Math.random() * 2;
          motionBlur.samples.value = 8 + Math.floor(Math.random() * 24);
          if (postProcessingRef.current.settings.motionBlur) {
            postProcessingRef.current.settings.motionBlur.motionScale = Math.random() * 2;
            postProcessingRef.current.settings.motionBlur.dampingFactor = 0.9 + Math.random() * 0.09;
          }
          motionBlurFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');
    }

    console.log('Post processing ref:', postProcessingRef.current);
    console.log('All passes:', postProcessingRef.current.passes);
    const glitchPass = postProcessingRef.current.passes.get('glitch');
    console.log('Glitch pass details:', {
      pass: glitchPass?.pass,
      material: glitchPass?.pass?.material,
      uniforms: glitchPass?.pass?.material?.uniforms
    });

    if (glitchPass?.pass?.material?.uniforms) {
      const glitchFolder = postProcessingFolder.addFolder('Glitch');
      const glitch = glitchPass.pass.material.uniforms;

      // Add randomize button at the top of glitch folder
      glitchFolder.add({
        randomize: () => {
          // Get current settings
          const currentSettings = postProcessingRef.current.getSettings();
          
          // Create new random settings
          const newGlitchSettings = {
            ...currentSettings.glitch,
            // Timing
            speed: 2 + Math.random() * 8,
            glitchInterval: 0.05 + Math.random() * 0.5,
            glitchDuration: 0.05 + Math.random() * 0.3,
            burstSpeed: 5 + Math.random() * 15,
            
            // Block glitch
            blockAmount: 0.5 + Math.random() * 0.5,
            blockSize: 0.05 + Math.random() * 0.45,
            blockOffset: 1 + Math.random() * 9,
            blockThreshold: 0.2 + Math.random() * 0.3,
            blockAlpha: 0.5 + Math.random() * 0.5,
            rgbOffset: 1 + Math.random() * 4,
            
            // Scanlines
            scanlineAmount: 0.5 + Math.random() * 0.5,
            scanlineSize: 5 + Math.random() * 20,
            distortionAmount: 0.5 + Math.random() * 0.5
          };
          
          // Update uniforms directly
          Object.entries(newGlitchSettings).forEach(([key, value]) => {
            if (glitch[key]) {
              glitch[key].value = value;
            }
          });
          
          // Update settings through PostProcessingStack
          postProcessingRef.current.updateSettings({
            ...currentSettings,
            glitch: newGlitchSettings
          });
          
          // Update GUI controls
          glitchFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');

      const timingFolder = glitchFolder.addFolder('Timing');
      timingFolder.add(glitch.speed, 'value', 0, 10).name('Speed');
      timingFolder.add(glitch.glitchInterval, 'value', 0.05, 2).name('Interval');
      timingFolder.add(glitch.glitchDuration, 'value', 0.01, 0.5).name('Duration');
      timingFolder.add(glitch.burstSpeed, 'value', 1, 20).name('Burst Speed');

      const blockFolder = glitchFolder.addFolder('Block Glitch');
      blockFolder.add(glitch.blockAmount, 'value', 0, 1).name('Intensity');
      blockFolder.add(glitch.blockSize, 'value', 0.01, 0.5).name('Size');
      blockFolder.add(glitch.blockOffset, 'value', 0.1, 10).name('Offset');
      blockFolder.add(glitch.blockThreshold, 'value', 0.1, 0.99).name('Threshold');
      blockFolder.add(glitch.blockAlpha, 'value', 0, 1).name('Alpha');
      blockFolder.add(glitch.rgbOffset, 'value', 0, 5).name('RGB Split');

      const scanlineFolder = glitchFolder.addFolder('Scanlines');
      scanlineFolder.add(glitch.scanlineAmount, 'value', 0, 1).name('Intensity');
      scanlineFolder.add(glitch.scanlineSize, 'value', 5, 50).name('Size');
      scanlineFolder.add(glitch.distortionAmount, 'value', 0, 1).name('Distortion');
    }

    if (postProcessingRef.current.passes.wave?.uniforms) {
      const waveFolder = postProcessingFolder.addFolder('Wave');
      const wave = postProcessingRef.current.passes.wave.uniforms;
      if (wave.amplitude) waveFolder.add(wave.amplitude, 'value', 0, 0.1).name('Amount');
      if (wave.frequency) waveFolder.add(wave.frequency, 'value', 0, 20).name('Frequency');
      if (wave.speed) waveFolder.add(wave.speed, 'value', 0, 5).name('Speed');

      waveFolder.add({
        randomize: () => {
          wave.amplitude.value = Math.random() * 0.1;
          wave.frequency.value = Math.random() * 20;
          wave.speed.value = Math.random() * 5;
          waveFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');
    }

    if (postProcessingRef.current.passes.dof?.uniforms) {
      const dofFolder = postProcessingFolder.addFolder('Depth of Field');
      const dof = postProcessingRef.current.passes.dof.uniforms;
      dofFolder.add(mouseDOFRef.current, 'enabled').name('Mouse Control');
      dofFolder.add(mouseDOFRef.current, 'sensitivity', 0.1, 5).name('Mouse Sensitivity');
      dofFolder.add(mouseDOFRef.current, 'smoothing', 0, 1).name('Focus Smoothing');
      dofFolder.add(mouseDOFRef.current, 'minFocus', 0, 10).name('Min Focus');
      dofFolder.add(mouseDOFRef.current, 'maxFocus', 0, 10).name('Max Focus');
      if (dof.focus) dofFolder.add(dof.focus, 'value', 0, 10).name('Base Focus');
      if (dof.aperture) dofFolder.add(dof.aperture, 'value', 0, 0.01).name('Aperture');
      if (dof.maxblur) dofFolder.add(dof.maxblur, 'value', 0, 0.02).name('Max Blur');

      dofFolder.add({
        randomize: () => {
          mouseDOFRef.current.sensitivity = 0.1 + Math.random() * 4.9;
          mouseDOFRef.current.smoothing = Math.random();
          mouseDOFRef.current.minFocus = Math.random() * 5;
          mouseDOFRef.current.maxFocus = 5 + Math.random() * 5;
          dof.focus.value = Math.random() * 10;
          dof.aperture.value = Math.random() * 0.01;
          dof.maxblur.value = Math.random() * 0.02;
          dofFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');
    }

    if (postProcessingRef.current.passes.filmGrain?.uniforms) {
      const filmGrainFolder = postProcessingFolder.addFolder('Film Grain');
      const filmGrain = postProcessingRef.current.passes.filmGrain;

      filmGrainFolder.add(filmGrain, 'enabled').name('Enabled');

      if (filmGrain.uniforms.intensity) {
        filmGrainFolder.add(filmGrain.uniforms.intensity, 'value', 0, 1)
          .name('Intensity')
          .onChange(value => {
            if (postProcessingRef.current.settings.filmGrain) {
              postProcessingRef.current.settings.filmGrain.intensity = value;
            }
          });
      }
      if (filmGrain.uniforms.speed) {
        filmGrainFolder.add(filmGrain.uniforms.speed, 'value', 0, 5)
          .name('Speed')
          .onChange(value => {
            if (postProcessingRef.current.settings.filmGrain) {
              postProcessingRef.current.settings.filmGrain.speed = value;
            }
          });
      }
      if (filmGrain.uniforms.grainSize) {
        filmGrainFolder.add(filmGrain.uniforms.grainSize, 'value', 0.1, 10)
          .name('Grain Size')
          .onChange(value => {
            if (postProcessingRef.current.settings.filmGrain) {
              postProcessingRef.current.settings.filmGrain.grainSize = value;
            }
          });
      }

      filmGrainFolder.add({
        randomize: () => {
          filmGrain.uniforms.intensity.value = Math.random();
          filmGrain.uniforms.speed.value = Math.random() * 5;
          filmGrain.uniforms.grainSize.value = 0.1 + Math.random() * 9.9;
          if (postProcessingRef.current.settings.filmGrain) {
            postProcessingRef.current.settings.filmGrain.intensity = filmGrain.uniforms.intensity.value;
            postProcessingRef.current.settings.filmGrain.speed = filmGrain.uniforms.speed.value;
            postProcessingRef.current.settings.filmGrain.grainSize = filmGrain.uniforms.grainSize.value;
          }
          filmGrainFolder.controllers.forEach(controller => controller.updateDisplay());
        }
      }, 'randomize').name('🎲 Randomize');
    }

    const orderFolder = postProcessingFolder.addFolder('Effect Order');
    Object.keys(effectOrderRef.current).forEach(effect => {
      orderFolder.add(effectOrderRef.current, effect, 0, 5, 1)
        .name(`${effect} Order`)
        .onChange(() => {
          postProcessingRef.current?.reorderPasses(effectOrderRef.current);
        });
    });

    if (postProcessingRef.current.passes.fade?.uniforms) {
        const fadeFolder = postProcessingFolder.addFolder('Fade');
        const fade = postProcessingRef.current.passes.fade;

        fadeFolder.add(fade.uniforms.fadeAmount, 'value', 0, 1)
            .name('Amount')
            .onChange(value => {
                postProcessingRef.current.updateFade(value);
            });

        fadeFolder.addColor({
            color: [0, 0, 0]
        }, 'color')
            .name('Color')
            .onChange(value => {
                const color = [
                    value[0] / 255,
                    value[1] / 255,
                    value[2] / 255
                ];
                postProcessingRef.current.updateFade(undefined, color);
            });

        fadeFolder.add({
            randomize: () => {
                const amount = Math.random();
                const color = [Math.random(), Math.random(), Math.random()];
                postProcessingRef.current.updateFade(amount, color);
                fadeFolder.controllers.forEach(controller => controller.updateDisplay());
            }
        }, 'randomize').name('🎲 Randomize');
    }
  }

  setupTransformControls(blob) {
    const transformFolder = this.gui.addFolder('Transform');
    transformFolder.add(blob.position, 'x', -10, 10).name('X Position');
    transformFolder.add(blob.position, 'y', -10, 10).name('Y Position');
    transformFolder.add(blob.position, 'z', -10, 10).name('Z Position');
    transformFolder.add(blob.rotation, 'x', -Math.PI, Math.PI).name('X Rotation');
    transformFolder.add(blob.rotation, 'y', -Math.PI, Math.PI).name('Y Rotation');
    transformFolder.add(blob.rotation, 'z', -Math.PI, Math.PI).name('Z Rotation');
  }

  setupAutomatedMovementControls(automatedMovementRef) {
    const movementFolder = this.gui.addFolder('Automated Movement');
    
    movementFolder.add(automatedMovementRef.current, 'enabled')
      .name('Enabled')
      .onChange(value => {
        automatedMovementRef.current.enabled = value;
        if (!value && automatedMovementRef.current.duration) {
          cancelAnimationFrame(automatedMovementRef.current.duration);
          automatedMovementRef.current.isActive = false;
          automatedMovementRef.current.duration = null;
        }
      });

    movementFolder.add(automatedMovementRef.current, 'interval', 1000, 10000)
      .name('Interval (ms)');
    
    movementFolder.add(automatedMovementRef.current, 'minDuration', 100, 2000)
      .name('Min Duration');
    
    movementFolder.add(automatedMovementRef.current, 'maxDuration', 100, 2000)
      .name('Max Duration');
    
    movementFolder.add(automatedMovementRef.current, 'minVelocity', -2, 0)
      .name('Min Velocity');
    
    movementFolder.add(automatedMovementRef.current, 'maxVelocity', 0, 2)
      .name('Max Velocity');
    
    movementFolder.add(automatedMovementRef.current, 'movementCount', 2, 16, 1)
      .name('Movement Count');

    movementFolder.add({
      randomize: () => {
        automatedMovementRef.current.interval = 1000 + Math.random() * 9000;
        automatedMovementRef.current.minDuration = 100 + Math.random() * 1000;
        automatedMovementRef.current.maxDuration = 
          automatedMovementRef.current.minDuration + Math.random() * 1000;
        automatedMovementRef.current.minVelocity = -2 * Math.random();
        automatedMovementRef.current.maxVelocity = 2 * Math.random();
        automatedMovementRef.current.movementCount = 2 + Math.floor(Math.random() * 14);
        movementFolder.controllers.forEach(controller => controller.updateDisplay());
      }
    }, 'randomize').name('🎲 Randomize');
  }

  setupStarsControls(stars) {
    if (!stars?.points) {
        console.warn('Stars object not ready');
        return;
    }

    const starsFolder = this.gui.addFolder('Stars');
    
    // Add randomize button
    starsFolder.add({
        randomize: () => {
            stars.updateSettings({
                minSize: 0.1 + Math.random() * 0.9,
                maxSize: 1.0 + Math.random() * 4.0,
                spread: 50 + Math.random() * 150,
                twinkleSpeed: Math.random() * 10
            });
        }
    }, 'randomize').name('🎲 Randomize');

    // Add controls for distribution
    const distributionFolder = starsFolder.addFolder('Distribution');
    distributionFolder.add(stars.settings, 'spread', 10, 200)
        .name('Spread')
        .onChange(() => stars.updateSettings(stars.settings));
    distributionFolder.add(stars.settings, 'depth', 10, 100)
        .name('Depth')
        .onChange(() => stars.updateSettings(stars.settings));

    // Add controls for appearance
    const appearanceFolder = starsFolder.addFolder('Appearance');
    appearanceFolder.add(stars.settings, 'minSize', 0.1, 1.0)
        .name('Min Size')
        .onChange(() => stars.updateSettings(stars.settings));
    appearanceFolder.add(stars.settings, 'maxSize', 1.0, 5.0)
        .name('Max Size')
        .onChange(() => stars.updateSettings(stars.settings));
    appearanceFolder.add(stars.settings, 'twinkleSpeed', 0, 10)
        .name('Twinkle Speed')
        .onChange((value) => stars.setTwinkleSpeed(value));
  }

  destroy() {
    if (this.gui) {
      this.gui.destroy();
    }
  }

  updateFPS(fps) {
    if (this.fpsCounter) {
      this.fpsCounter.value = fps;
    }
  }
} 