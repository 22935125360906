export const DigitalGlitchShader = {
  uniforms: {
    'tDiffuse': { value: null },
    'time': { value: 0.0 },
    'blockAmount': { value: 0.08 },
    'scanlineAmount': { value: 0.08 },
    'speed': { value: 1.0 },
    'distortionAmount': { value: 0.4 },
    'blockSize': { value: 0.05 },
    'scanlineSize': { value: 30.0 },
    'blockOffset': { value: 2.0 },
    'blockThreshold': { value: 0.95 },
    'blockAlpha': { value: 1.0 },
    'rgbOffset': { value: 0.5 },
    'glitchInterval': { value: 1.0 },
    'glitchDuration': { value: 0.1 },
    'burstSpeed': { value: 2.0 },
    'seed': { value: 0.0 }
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform float time;
    uniform float blockAmount;
    uniform float scanlineAmount;
    uniform float speed;
    uniform float distortionAmount;
    uniform float blockSize;
    uniform float scanlineSize;
    uniform float blockOffset;
    uniform float blockThreshold;
    uniform float blockAlpha;
    uniform float rgbOffset;
    uniform float glitchInterval;
    uniform float glitchDuration;
    uniform float burstSpeed;
    uniform float seed;
    varying vec2 vUv;

    float random(vec2 c) {
      return fract(sin(dot(c.xy + seed, vec2(12.9898, 78.233))) * 43758.5453);
    }

    void main() {
      vec2 uv = vUv;
      float t = mod(time * speed, 100.0);
      
      // Calculate glitch timing
      float glitchPhase = mod(t, glitchInterval);
      float glitchRandom = random(vec2(floor(t / glitchInterval)));
      bool isGlitching = glitchPhase < glitchDuration && glitchRandom > 0.3;
      
      if (isGlitching) {
        float burstT = mod(t * burstSpeed, 1.0);
        
        // Block glitches - using blockAmount
        float blockNoise = random(vec2(floor(burstT * 15.0)));
        if (blockNoise > blockThreshold) {
          vec2 blockPos = floor(uv / blockSize) * blockSize;
          float blockR = random(blockPos + burstT);
          float blockG = random(blockPos + burstT + 1.0);
          float blockB = random(blockPos + burstT + 2.0);
          
          vec2 uvR = uv + vec2((blockR - 0.5) * blockOffset * blockAmount, 0.0) * rgbOffset;
          vec2 uvG = uv + vec2((blockG - 0.5) * blockOffset * blockAmount, 0.0) * rgbOffset;
          vec2 uvB = uv + vec2((blockB - 0.5) * blockOffset * blockAmount, 0.0) * rgbOffset;
          
          vec4 normalColor = texture2D(tDiffuse, uv);
          vec4 rColor = texture2D(tDiffuse, uvR);
          vec4 gColor = texture2D(tDiffuse, uvG);
          vec4 bColor = texture2D(tDiffuse, uvB);
          
          vec4 blockColor = vec4(rColor.r, gColor.g, bColor.b, 1.0);
          gl_FragColor = mix(normalColor, blockColor, blockAlpha);
          return;
        }

        // Scanlines - using scanlineAmount
        float scanline = floor(uv.y * scanlineSize) / scanlineSize;
        float scanlineNoise = random(vec2(scanline, burstT));
        if (scanlineNoise > 0.96) {
          uv.x += (random(vec2(scanline, burstT)) - 0.5) * scanlineAmount;
        }
      }
      
      gl_FragColor = texture2D(tDiffuse, uv);
    }
  `
}; 