import * as THREE from 'three';

export class PostProcessingConfig {
  constructor(settings = {}, layerToggles = {}, quality = 'high') {
    this.settings = this.validateSettings(settings);
    this.layerToggles = this.validateToggles(layerToggles);
    this.quality = quality;
    
    // Default pass order
    this.defaultOrder = {
      render: -1,  // Always first
      bloom: 0,
      rgbShift: 1,
      motionBlur: 2,
      glitch: 3,
      wave: 4,
      dof: 5,
      fade: 6,
      filmGrain: 7
    };
  }

  validateSettings(settings) {
    return {
      bloom: {
        strength: settings.bloomStrength ?? 1.8,
        radius: settings.bloomRadius ?? 1.0,
        threshold: settings.bloomThreshold ?? 0.362
      },
      rgbShift: {
        baseAmount: settings.rgbShift?.baseAmount ?? 0.003,
        motionMultiplier: settings.rgbShift?.motionMultiplier ?? 1.0,
        enabled: settings.rgbShift?.enabled ?? true
      },
      motionBlur: {
        intensity: settings.motionBlur?.intensity ?? 1.0,
        samples: settings.motionBlur?.samples ?? 16
      },
      glitch: {
        amount: settings.glitch?.amount ?? 0.08,
        speed: settings.glitch?.speed ?? 1.0
      },
      wave: {
        ...settings.wave
      },
      fade: {
        amount: settings.fade?.amount ?? 0,
        color: settings.fade?.color ?? [0, 0, 0]
      },
      filmGrain: {
        intensity: settings.filmGrain?.intensity ?? 0.15,
        speed: settings.filmGrain?.speed ?? 1.0,
        grainSize: settings.filmGrain?.grainSize ?? 1.0,
        enabled: settings.filmGrain?.enabled ?? true
      },
      dof: {
        focus: settings.dof?.focus ?? 1.0,
        aperture: settings.dof?.aperture ?? 0.025,
        maxblur: settings.dof?.maxblur ?? 1.0,
        enabled: settings.dof?.enabled ?? true
      },
      effectOrder: this.validateEffectOrder(settings.effectOrder)
    };
  }

  validateEffectOrder(order = {}) {
    // Merge provided order with defaults, keeping custom orders
    return {
      ...this.defaultOrder,
      ...order
    };
  }

  validateToggles(toggles) {
    return {
      bloom: toggles.bloom ?? true,
      rgbShift: toggles.rgbShift ?? true,
      motionBlur: toggles.motionBlur ?? true,
      glitch: toggles.glitch ?? true,
      wave: toggles.wave ?? true,
      fade: toggles.fade ?? true,
      filmGrain: toggles.filmGrain ?? true,
      dof: toggles.dof ?? true
    };
  }

  getQualitySettings(quality) {
    const qualityMap = {
      low: { resolutionScale: 0.5, maxSamples: 8 },
      medium: { resolutionScale: 0.75, maxSamples: 16 },
      high: { resolutionScale: 1.0, maxSamples: 32 }
    };
    return qualityMap[quality] ?? qualityMap.high;
  }
} 