import React, { useEffect, useRef, useState } from 'react';

const Cursor = ({ hasInteracted, onLongPressComplete }) => {
  const cursorRef = useRef(null);
  const isVisible = useRef(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHolding, setIsHolding] = useState(false);
  const [holdProgress, setHoldProgress] = useState(0);
  const holdAnimationRef = useRef(null);
  const mouseDownTimeRef = useRef(null);
  const checkHoldTimeoutRef = useRef(null);
  const HOLD_DURATION = 500;
  const CLICK_THRESHOLD = 150; // Time in ms to consider a "quick click"
  const [isFlashing, setIsFlashing] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('Hold to randomise');
  const TOOLTIP_TIMEOUT = 2000; // Tooltip will always hide after 2 seconds
  const tooltipTimeoutRef = useRef(null);

  // Function to handle tooltip timeout
  const startTooltipTimeout = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    tooltipTimeoutRef.current = setTimeout(() => {
      setIsTooltipVisible(false);
    }, TOOLTIP_TIMEOUT);
  };

  useEffect(() => {
    // Check if it's a touch device
    const checkTouchDevice = () => {
      setIsTouchDevice(('ontouchstart' in window) || 
        (navigator.maxTouchPoints > 0) || 
        (navigator.msMaxTouchPoints > 0));
    };

    checkTouchDevice();
    
    // If it's a touch device, don't set up any event listeners
    if (isTouchDevice) return;

    // Start initial tooltip timeout
    startTooltipTimeout();

    const updatePosition = (e) => {
      if (cursorRef.current) {
        cursorRef.current.style.setProperty('--cursor-x', `${e.clientX}px`);
        cursorRef.current.style.setProperty('--cursor-y', `${e.clientY}px`);
        cursorRef.current.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;
        
        if (!isVisible.current) {
          isVisible.current = true;
          cursorRef.current.style.opacity = 1;
        }
      }
    };

    const handleMouseDown = (event) => {
      mouseDownTimeRef.current = performance.now();
      
      checkHoldTimeoutRef.current = setTimeout(() => {
        setIsHolding(true);
        setShowTooltip(true);
        setIsTooltipVisible(true);
        startTooltipTimeout();
        
        const startTime = performance.now();
        
        const animateHold = () => {
          const elapsed = performance.now() - startTime;
          const progress = Math.min(elapsed / HOLD_DURATION, 1);
          setHoldProgress(progress);
          
          if (progress === 1) {
            // Trigger randomization when progress completes
            onLongPressComplete?.();
            setIsTooltipVisible(false);
            
            // Trigger double flash
            setIsFlashing(true);
            setTimeout(() => {
              setIsFlashing(false);
              setTimeout(() => {
                setIsFlashing(true);
                setTimeout(() => {
                  setIsFlashing(false);
                }, 100);
              }, 100);
            }, 100);
          }
          
          if (progress < 1) {
            holdAnimationRef.current = requestAnimationFrame(animateHold);
          }
        };
        
        holdAnimationRef.current = requestAnimationFrame(animateHold);
      }, CLICK_THRESHOLD);
    };

    const handleMouseUp = (event) => {
      if (checkHoldTimeoutRef.current) {
        clearTimeout(checkHoldTimeoutRef.current);
      }
      
      setIsHolding(false);
      setHoldProgress(0);
      setIsTooltipVisible(false);
      
      if (holdAnimationRef.current) {
        cancelAnimationFrame(holdAnimationRef.current);
      }
      
      mouseDownTimeRef.current = null;
    };

    const handleMouseLeave = () => {
      isVisible.current = false;
      if (cursorRef.current) {
        cursorRef.current.style.opacity = 0;
      }
    };

    const handleHoverableElements = (e) => {
      const target = e.target;
      const isHoverable = target.matches('a, button, [role="button"], input, select, textarea, .hoverable');
      setIsExpanded(isHoverable);
    };

    document.addEventListener('mousemove', updatePosition);
    document.addEventListener('mouseover', handleHoverableElements);
    document.addEventListener('mouseleave', handleMouseLeave);
    document.addEventListener('mouseenter', updatePosition);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', updatePosition);
      document.removeEventListener('mouseover', handleHoverableElements);
      document.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('mouseenter', updatePosition);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      if (holdAnimationRef.current) {
        cancelAnimationFrame(holdAnimationRef.current);
      }
      if (checkHoldTimeoutRef.current) {
        clearTimeout(checkHoldTimeoutRef.current);
      }
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, [hasInteracted, isTouchDevice]);

  // Don't render anything if it's a touch device
  if (isTouchDevice) return null;

  return (
    <div 
      ref={cursorRef}
      className={`cursor-dot fixed pointer-events-none z-[9999] opacity-0 -translate-x-2 -translate-y-2
        ${isExpanded ? 'cursor-expanded' : ''}
        hidden md:block`}
    >
      {/* Progress Ring */}
      {isHolding && (
        <svg 
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90"
          width="32"
          height="32"
        >
          <circle
            cx="16"
            cy="16"
            r="12"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeDasharray={`${2 * Math.PI * 12 * holdProgress} ${2 * Math.PI * 12}`}
            className="mix-blend-difference"
          />
        </svg>
      )}
      
      {/* Cursor Dot */}
      <div 
        className={`w-4 h-4 bg-white rounded-full mix-blend-difference transition-transform duration-200
          ${isExpanded ? 'scale-150' : 'scale-100'}
          ${isFlashing ? 'scale-[2] opacity-50' : ''}`}
        style={{
          transition: isFlashing ? 'all 100ms ease-out' : 'all 200ms ease-out'
        }}
      />

      {/* Updated Tooltip - removed the dot */}
      {showTooltip && (
        <div 
          className={`absolute top-8 left-1/2 -translate-x-1/2 whitespace-nowrap 
            bg-black/90 backdrop-blur-sm px-4 py-1.5 rounded-full
            border border-white/10 
            transition-all duration-700
            ${isTooltipVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1'}`}
          style={{ 
            boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
            transform: 'translate(-50%, 0px) scale(0.95)',
            animation: isTooltipVisible ? 'tooltipFloat 2s ease-in-out infinite' : 'none'
          }}
          onTransitionEnd={() => {
            if (!isTooltipVisible) {
              setShowTooltip(false);
            }
          }}
        >
          <span className="text-[10px] text-white/90 font-light tracking-widest uppercase">
            {tooltipMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default Cursor; 