import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { projects } from '../config/constants';

function SelectedWork() {
  const [hoveredProject, setHoveredProject] = useState(null);
  
  const selectedProjects = projects.filter(project => !project.password && !project.hidden);

  const getDisplayImage = (project) => {
    if (hoveredProject === project.id && project.gif) {
      return project.gif;
    }
    return project.image;
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-3 gap-y-8 p-4">
        {selectedProjects.map((project, index) => (
          <Link 
            to={`/project/${project.id}`} 
            key={index} 
            className="flex flex-col gap-3 group"
            onMouseEnter={() => setHoveredProject(project.id)}
            onMouseLeave={() => setHoveredProject(null)}
          >
            <div className="relative aspect-w-16 aspect-h-9">
              <img
                src={getDisplayImage(project)}
                alt={project.title}
                className="w-full h-full object-cover rounded-3xl"
                loading="lazy"
              />
              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-300 ease-in-out rounded-3xl"></div>
            </div>
            <div>
              <h3 className="text-white text-sm">{project.title}</h3>
              <p className="text-gray-400 text-xs mt-1">{project.client} • {project.agency} • {project.type}</p>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default SelectedWork;
