import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const PerformanceContext = createContext();

export const PerformanceProvider = ({ children }) => {
  const [quality, setQuality] = useState(() => {
    return 'low';
  });

  const [fps, setFps] = useState(60);
  const lowFpsCount = useRef(0);
  const lastQualityChange = useRef(Date.now());

  const FPS_THRESHOLD = 15;
  const SAMPLES_NEEDED = 30;
  const COOLDOWN_PERIOD = 10000;

  useEffect(() => {
    return;
  }, [fps, quality]);

  const updateQuality = (newQuality) => {
    console.log('Quality locked to low');
    setQuality('low');
    localStorage.setItem('graphicsQuality', 'low');
    lastQualityChange.current = Date.now();
    lowFpsCount.current = 0;
  };

  return (
    <PerformanceContext.Provider value={{ 
      quality, 
      updateQuality,
      fps,
      setFps,
      qualitySettings: {
        low: {
          resolutionScale: 0.75,
          antiAlias: true,
          blobDetail: 64,
          materialQuality: {
            samples: 1
          },
          morphingQuality: {
            vertexStride: 1,
            normalUpdateInterval: 4
          }
        },
        medium: {
          resolutionScale: 0.75,
          antiAlias: true,
          blobDetail: 96,
          materialQuality: {
            samples: 2
          },
          morphingQuality: {
            vertexStride: 1,
            normalUpdateInterval: 2
          }
        },
        high: {
          resolutionScale: 1.0,
          antiAlias: true,
          blobDetail: 128,
          materialQuality: {
            samples: 4
          },
          morphingQuality: {
            vertexStride: 1,
            normalUpdateInterval: 1
          }
        }
      }
    }}>
      {children}
    </PerformanceContext.Provider>
  );
};

export const usePerformance = () => useContext(PerformanceContext); 