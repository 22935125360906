import React from 'react';
import SelectedWork from './SelectedWork';
import About from './About';
import Footer from './Footer';
import ScrollIndicator from './ScrollIndicator';

function MainContent({ onProjectClick }) {
  return (
    <>
      <div className="h-[100vh] w-full flex items-center justify-center relative">
        {/* Spacer for ARScene */}
        <ScrollIndicator />
      </div>
      
      <div className="relative z-10">
        <div className="bg-gradient-to-b from-transparent to-[#121212] pt-32">
          <SelectedWork />
          <About />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default MainContent;
