import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import MainContent from './components/MainContent';
import ProjectPage from './components/ProjectPage';
import Container from './components/Container';
import ARScene from './components/ARScene';
import Cursor from './components/Cursor';
import './App.css';
import ReactGA from "react-ga4";
import { PerformanceProvider } from './context/PerformanceContext';
import PerformanceControls from './components/PerformanceControls';

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(location);
  const isInitialMount = useRef(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const hasInteracted = useRef(false);

  const startAudio = async () => {
    if (!hasInteracted.current && audioRef.current) {
      try {
        audioRef.current.volume = 0;
        await audioRef.current.play();
        // Fade in
        const fadeIn = setInterval(() => {
          if (audioRef.current.volume < 0.9) {
            audioRef.current.volume += 0.1;
          } else {
            clearInterval(fadeIn);
            audioRef.current.volume = 1;
          }
        }, 50);
        setIsPlaying(true);
        hasInteracted.current = true;
      } catch (error) {
        console.log('Audio playback failed:', error);
      }
    }
  };

  const toggleAudio = async () => {
    if (audioRef.current) {
      if (isPlaying) {
        // Fade out
        const fadeOut = setInterval(() => {
          if (audioRef.current.volume > 0.1) {
            audioRef.current.volume -= 0.1;
          } else {
            clearInterval(fadeOut);
            audioRef.current.pause();
            audioRef.current.volume = 1;
            setIsPlaying(false);
          }
        }, 50);
      } else {
        audioRef.current.volume = 0;
        await audioRef.current.play();
        // Fade in
        const fadeIn = setInterval(() => {
          if (audioRef.current.volume < 0.9) {
            audioRef.current.volume += 0.1;
          } else {
            clearInterval(fadeIn);
            audioRef.current.volume = 1;
          }
        }, 50);
        setIsPlaying(true);
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 50);
    } else if (location !== currentLocation) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentLocation(location);
        window.scrollTo(0, 0);
        setTimeout(() => {
          setIsTransitioning(false);
        }, 50);
      }, 300);
    }
  }, [location, currentLocation]);

  const handleNavigation = useCallback((path) => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate(path);
    }, 150);
  }, [navigate]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <div 
      className="App bg-[#121212] min-h-screen relative"
      onClick={!hasInteracted.current ? startAudio : undefined}
    >
      <Cursor hasInteracted={hasInteracted.current} />
      <div className="fixed inset-0 w-full h-full z-0">
        <ARScene />
      </div>
      
      <div className="relative">
        <Container>
          <Header isPlaying={isPlaying} onToggleAudio={toggleAudio} />
          <div className={`content-wrapper ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
            <Routes location={currentLocation}>
              <Route path="/" element={<MainContent onProjectClick={handleNavigation} />} />
              <Route path="/project/:id" element={<ProjectPage />} />
            </Routes>
          </div>
        </Container>
      </div>

      <audio
        ref={audioRef}
        src="/audio/Cloudy_Changes_2.mp3"
        loop
        preload="auto"
        className="hidden"
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <PerformanceProvider>
        <AppContent />
      </PerformanceProvider>
    </Router>
  );
}

export default App;