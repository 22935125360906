import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as THREE from 'three';

const TitleOverlay = () => {
  const [opacity, setOpacity] = useState(1);
  const [scale, setScale] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const scrollRAF = useRef(null);
  const scrollY = useRef(0);
  const initialY = useRef(window.scrollY);

  // Check if we're on a project page
  const isProjectPage = location.pathname.includes('/project/');

  useEffect(() => {
    // Reset states and scroll position when returning to home page
    if (!isProjectPage) {
      window.scrollTo(0, 0);
      setOpacity(1);
      setScale(1);
      setIsVisible(false);
      initialY.current = 0;
      scrollY.current = 0;
      
      // Reset visibility after a short delay
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [location.pathname, isProjectPage]);

  useEffect(() => {
    if (isProjectPage) return;

    const handleScroll = () => {
      if (scrollRAF.current) return;
      
      scrollRAF.current = requestAnimationFrame(() => {
        scrollY.current = window.scrollY;
        const scrollOffset = scrollY.current - initialY.current;
        
        // Simple fade calculation
        const fadeStart = 100;
        const fadeEnd = window.innerHeight * 0.4;
        const newOpacity = Math.max(0, 1 - (Math.abs(scrollOffset) / fadeEnd));
        const newScale = 1 + (1 - newOpacity) * 0.1;
        
        setOpacity(newOpacity);
        setScale(newScale);

        forceUpdate(v => !v);
        scrollRAF.current = null;
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollRAF.current) {
        cancelAnimationFrame(scrollRAF.current);
      }
    };
  }, [isProjectPage]);

  // Add forceUpdate hook to trigger re-renders
  const [, forceUpdate] = useState(false);

  if (isProjectPage) {
    return null;
  }

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center pointer-events-none select-none mix-blend-difference z-[1000]"
      style={{
        transform: `translateY(${-(scrollY.current - initialY.current) * 0.5}px) scale(${scale})`,
        opacity,
        transition: 'opacity 0.3s ease-out, transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
        willChange: 'transform'
      }}
    >
      <div 
        className="flex flex-col items-center w-full"
        style={{
          transform: `translateY(${(scrollY.current - initialY.current) * 0.1}px)`,
          transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)'
        }}
      >
        <div 
          className={`w-[85vw] md:w-[60vw] lg:w-[40vw] mx-auto transition-all duration-[1200ms] ease-out ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'
          }`}
        >
          <img 
            src="/images/dan-samson.svg" 
            alt="Dan Samson" 
            className="w-full h-auto"
            style={{
              filter: `
                brightness(1.2)
                contrast(1.2)
                drop-shadow(0 -1px 0 rgba(255,255,255,0.4))
                drop-shadow(0 1px 1px rgba(0,0,0,0.3))
                drop-shadow(0 0 25px rgba(0,255,143,0.2))
                drop-shadow(0 0 45px rgba(0,255,143,0.1))
              `,
              mixBlendMode: 'overlay'
            }}
          />
        </div>

        <div 
          className={`w-[8vw] md:w-[5vw] lg:w-[3vw] h-[1px] bg-white/60 my-4 md:my-6 lg:my-7 transition-all duration-1000 delay-500 ${
            isVisible ? 'opacity-100 scale-x-100' : 'opacity-0 scale-x-0'
          }`}
          style={{
            boxShadow: '0 0 15px rgba(0,255,143,0.3)'
          }}
        />

        <div 
          className={`text-xs md:text-[11px] lg:text-medium font-normal text-white tracking-[0.25em] md:tracking-[0.3em] lg:tracking-[0.4em] uppercase transition-all duration-1000 delay-[750ms] ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'
          }`}
          style={{
            textShadow: '0 0 15px rgba(0,255,143,0.3), 0 0 30px rgba(0,255,143,0.1)'
          }}
        >
          Creative Director / Technologist
        </div>
      </div>
    </div>
  );
};

export default TitleOverlay; 