import React, { useEffect, useRef, useCallback, useState } from 'react';
import * as THREE from 'three';
import { createNoise3D } from 'simplex-noise';
import { defaultSettings } from '../config/blobSettings.js';
import TitleOverlay from './TitleOverlay';
import { usePerformance } from '../context/PerformanceContext';
import { BlobAnimation } from '../animation/BlobAnimation';
import { PostProcessingStack } from '../postprocessing/PostProcessingStack';
import { BlobGUI } from '../gui/BlobGUI';
import { Stars } from './Stars';
import Cursor from './Cursor';

const ARScene = () => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const { quality, qualitySettings, setFps } = usePerformance();
  const settings = qualitySettings[quality];
  const frameCount = useRef(0);
  const lastTime = useRef(performance.now());
  const fpsUpdateInterval = useRef(null);
  const guiRef = useRef(null);
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const blobRef = useRef(null);
  const composerRef = useRef(null);
  const timeRef = useRef(0);
  const noise3D = createNoise3D();
  const settingsRef = useRef(defaultSettings);
  const mouseRef = useRef({ x: 0, y: 0 });
  const mouseVelocityRef = useRef({ x: 0, y: 0 });
  const mouseDOFRef = useRef({
    enabled: true,
    sensitivity: 2.0,
    minFocus: 2.0,
    maxFocus: 6.0,
    smoothing: 0.1
  });

  // Add effect order ref
  const effectOrderRef = useRef({
    bloom: 0,
    rgbShift: 1,
    motionBlur: 2,
    glitch: 3,
    wave: 4,
    dof: 5
  });

  // Add layer toggles ref
  const layerTogglesRef = useRef({
    bloom: true,
    rgbShift: true,
    motionBlur: true,
    glitch: true,
    wave: true,
    depthOfField: true
  });

  // Add animation settings ref
  const animationSettingsRef = useRef({
    timeScale: 0.012,
    positionAmplitude: 0.3,
    rotationSpeed: 0.5,
    noiseScale1: 3,
    noiseScale2: 6,
    noiseScale3: 1.5,
    noiseInfluence1: 1,
    noiseInfluence2: 0.3,
    noiseInfluence3: 1.2,
    deformationStrength: 0.3,
    heartbeatSpeed: 1.0,
    heartbeatStrength: 0.2,
    heartbeatSharpness: 2.0,
    verticalBias: 0.7,
    noiseAmplitude: 1.0,
    timeScale1: 1.2,
    timeScale2: 0.8,
    timeScale3: 0.4
  });

  // Add ref for automated movement with settings from blobSettings
  const automatedMovementRef = useRef({
    enabled: settingsRef.current.automatedMovement?.enabled ?? true,
    interval: settingsRef.current.automatedMovement?.interval ?? 4000,
    minDuration: settingsRef.current.automatedMovement?.minDuration ?? 500,
    maxDuration: settingsRef.current.automatedMovement?.maxDuration ?? 1000,
    minVelocity: settingsRef.current.automatedMovement?.minVelocity ?? -1.0,
    maxVelocity: settingsRef.current.automatedMovement?.maxVelocity ?? 1.0,
    movementCount: settingsRef.current.automatedMovement?.movementCount ?? 8,
    isActive: false,
    duration: null
  });

  // Add refs for shader passes and material
  const materialRef = useRef(null);
  const iorAnimationSettingsRef = useRef({
    enabled: true,
    speed: 0.5,
    minIOR: 1.0,
    maxIOR: 2.333,
    amplitude: 0.5
  });

  // Add refs for animation and post-processing
  const blobAnimationRef = useRef(null);
  const postProcessingRef = useRef(null);

  // Add stars ref
  const starsRef = useRef(null);

  // Add refs for lights
  const ambientLightRef = useRef(null);
  const directionalLightRef = useRef(null);
  const pointLightRef = useRef(null);

  // Add a key for forcing remounts
  const [mountKey, setMountKey] = useState(0);

  // Update FPS monitoring
  useEffect(() => {
    // Update FPS every second
    fpsUpdateInterval.current = setInterval(() => {
      const currentTime = performance.now();
      const deltaTime = currentTime - lastTime.current;
      const currentFps = Math.round((frameCount.current * 1000) / deltaTime);
      
      setFps(currentFps > 0 ? currentFps : 0);
      
      // Update GUI FPS counter if it exists
      if (guiRef.current?.updateFPS) {
        guiRef.current.updateFPS(currentFps > 0 ? currentFps : 0);
      }
      
      // Reset counters
      frameCount.current = 0;
      lastTime.current = currentTime;
    }, 1000);

    return () => {
      if (fpsUpdateInterval.current) {
        clearInterval(fpsUpdateInterval.current);
      }
    };
  }, [setFps]);

  // Modified animation loop
  const animate = useCallback(() => {
    frameCount.current++;

    if (blobAnimationRef.current) {
      blobAnimationRef.current.animate();
    }

    // Update stars time uniform - Add null check
    if (starsRef.current?.points?.material?.uniforms) {
      starsRef.current.update(performance.now() * 0.001);
    }

    // Update time uniforms for shaders
    const time = performance.now() * 0.001;
    if (postProcessingRef.current) {
      postProcessingRef.current.updateTime(time);
      postProcessingRef.current.render();
    }

    requestAnimationFrame(animate);
  }, []); 

  // Start animation loop
  useEffect(() => {
    const animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [animate]);

  // Apply quality settings to your Three.js scene
  useEffect(() => {
    if (!rendererRef.current || !composerRef.current || !blobRef.current) return;

    // Update renderer resolution
    const pixelRatio = settings.antiAlias ? window.devicePixelRatio : 1;
    rendererRef.current.setPixelRatio(pixelRatio);

    // Update render resolution based on quality
    const width = window.innerWidth;
    const height = window.innerHeight;
    
    // Set renderer size to actual window size for proper viewport
    rendererRef.current.setSize(width, height);
    
    // Set internal render size based on resolution scale
    rendererRef.current.domElement.style.width = '100%';
    rendererRef.current.domElement.style.height = '100%';
    rendererRef.current.setSize(
      width * settings.resolutionScale, 
      height * settings.resolutionScale, 
      false
    );
    
    // Update composer size to match render resolution
    composerRef.current.setSize(
      width * settings.resolutionScale, 
      height * settings.resolutionScale
    );

    // Update blob geometry detail
    if (blobRef.current) {
      const geometry = new THREE.SphereGeometry(
        2.0, 
        settings.blobDetail,
        settings.blobDetail
      );
      blobRef.current.geometry.dispose();
      blobRef.current.geometry = geometry;
    }

    // Update material quality
    if (materialRef.current) {
      materialRef.current.roughness = settings.materialQuality.roughness;
      materialRef.current.envMapIntensity = settings.materialQuality.envMapIntensity;
    }

    // Force renderer to update its internal state
    rendererRef.current.getSize(new THREE.Vector2());

  }, [quality, settings]);

  // Modified scene initialization
  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    sceneRef.current = scene;

    // Camera setup
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 1.6, 0);
    cameraRef.current = camera;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    // Set initial size - will be updated by quality settings effect
    renderer.setSize(
      window.innerWidth * settings.resolutionScale, 
      window.innerHeight * settings.resolutionScale, 
      false
    );
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    rendererRef.current = renderer;
    renderer.domElement.style.width = '100%';
    renderer.domElement.style.height = '100%';
    containerRef.current?.appendChild(renderer.domElement);

    // Make the renderer's canvas non-interactive
    renderer.domElement.style.pointerEvents = 'none';

    // Lighting setup
    const ambientLight = new THREE.AmbientLight(0xffffff, settingsRef.current.lighting.ambient);
    ambientLightRef.current = ambientLight;
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, settingsRef.current.lighting.directional);
    directionalLight.position.set(1, 1, 1);
    directionalLightRef.current = directionalLight;
    scene.add(directionalLight);

    const pointLight = new THREE.PointLight(
      settingsRef.current.lighting.pointColor,
      settingsRef.current.lighting.point,
      10,
      2
    );
    pointLight.position.set(
      settingsRef.current.lighting.position.x,
      settingsRef.current.lighting.position.y,
      settingsRef.current.lighting.position.z
    );
    pointLightRef.current = pointLight;
    scene.add(pointLight);

    // Create environment map
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    const envMapTexture = pmremGenerator.fromScene(new THREE.Scene()).texture;
    pmremGenerator.dispose();

    // Create blob geometry with quality-based detail
    const geometry = new THREE.SphereGeometry(
      2.0, 
      settings.blobDetail, 
      settings.blobDetail
    );

    // Update animation settings from blobSettings.json
    Object.assign(animationSettingsRef.current, {
      ...settingsRef.current.animation,
      heartbeatSpeed: settingsRef.current.animation.heartbeat?.speed ?? 1.0,
      heartbeatStrength: settingsRef.current.animation.heartbeat?.strength ?? 0.2,
      heartbeatSharpness: settingsRef.current.animation.heartbeat?.sharpness ?? 2.0,
      noiseScale1: settingsRef.current.noise?.noiseScale1 ?? 3.0,
      noiseScale2: settingsRef.current.noise?.noiseScale2 ?? 6.0,
      noiseScale3: settingsRef.current.noise?.noiseScale3 ?? 1.5,
      noiseInfluence1: settingsRef.current.noise?.noiseInfluence1 ?? 1.0,
      noiseInfluence2: settingsRef.current.noise?.noiseInfluence2 ?? 0.3,
      noiseInfluence3: settingsRef.current.noise?.noiseInfluence3 ?? 1.2,
      deformationStrength: settingsRef.current.noise?.deformationStrength ?? 0.3,
      verticalBias: settingsRef.current.noise?.verticalBias ?? 0.7,
      noiseAmplitude: settingsRef.current.noise?.noiseAmplitude ?? 1.0,
      timeScale1: settingsRef.current.noise?.timeScale1 ?? 1.2,
      timeScale2: settingsRef.current.noise?.timeScale2 ?? 0.8,
      timeScale3: settingsRef.current.noise?.timeScale3 ?? 0.4
    });

    // Update IOR animation settings
    Object.assign(iorAnimationSettingsRef.current, settingsRef.current.iorAnimation);

    // Update layer toggles
    Object.assign(layerTogglesRef.current, settingsRef.current.layerToggles);

    // Update effect order
    Object.assign(effectOrderRef.current, settingsRef.current.effectOrder);

    // Create blob material
    const material = new THREE.MeshPhysicalMaterial({
      color: new THREE.Color(...settingsRef.current.material.color),
      emissive: new THREE.Color(...settingsRef.current.material.emissive),
      metalness: settingsRef.current.material.metalness ?? 0,
      roughness: settingsRef.current.material.roughness ?? 0.5,
      emissiveIntensity: settingsRef.current.material.emissiveIntensity ?? 0.87,
      envMap: envMapTexture,
      envMapIntensity: settingsRef.current.material.envMapIntensity ?? 0.92,
      clearcoat: settingsRef.current.material.clearcoat ?? 0,
      clearcoatRoughness: settingsRef.current.material.clearcoatRoughness ?? 0,
      sheen: settingsRef.current.material.sheen ?? 1,
      sheenRoughness: settingsRef.current.material.sheenRoughness ?? 0.224,
      sheenColor: new THREE.Color(
        ...(settingsRef.current.material.sheenColor ?? [2, 2, 2])
      ),
      iridescence: settingsRef.current.material.iridescence ?? 0.898,
      iridescenceIOR: settingsRef.current.material.iridescenceIOR ?? 1.654,
      transmission: settingsRef.current.material.transmission ?? 0.326,
      ior: settingsRef.current.material.ior ?? 2.26,
      thickness: settingsRef.current.material.thickness ?? 4.429,
      attenuationColor: new THREE.Color(
        ...(settingsRef.current.material.attenuationColor ?? [1, 1, 1])
      ),
      attenuationDistance: settingsRef.current.material.attenuationDistance ?? 0.5,
      reflectivity: settingsRef.current.material.reflectivity ?? 0.5,
      transparent: true,
      side: THREE.DoubleSide
    });
    materialRef.current = material;

    const blob = new THREE.Mesh(geometry, material);
    blob.position.set(0, 2.5, -4);
    scene.add(blob);
    blobRef.current = blob;

    // Initialize BlobAnimation AFTER blob is created
    blobAnimationRef.current = new BlobAnimation({
      blob: blobRef.current,
      noise3D,
      animationSettingsRef,
      iorAnimationSettingsRef,
      material: materialRef.current,
      timeRef,
      mouseVelocityRef,
      mouseDOFRef,
      postProcessingRef,
      qualitySettings: settings
    });

    // Initialize PostProcessingStack AFTER renderer is created
    postProcessingRef.current = new PostProcessingStack(
      renderer,
      scene,
      camera,
      settingsRef.current.postProcessing,
      settingsRef.current.layerToggles,
      quality
    );

    // Handle resize
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      
      // Update sizes maintaining resolution scale
      renderer.setSize(width, height);
      renderer.setSize(
        width * settings.resolutionScale, 
        height * settings.resolutionScale, 
        false
      );
      if (postProcessingRef.current) {
        postProcessingRef.current.updateSize(
          width * settings.resolutionScale, 
          height * settings.resolutionScale
        );
      }
    };

    let lastScrollY = window.scrollY;
    let scrollRAF = null;
    let smoothedScrollY = window.scrollY;
    const scrollEasing = 0.1; // Adjust this value for smoother/faster transitions
  
    // Handle scroll
    const handleScroll = () => {
      if (scrollRAF) return;
      
      scrollRAF = requestAnimationFrame(() => {
        const currentScrollY = window.scrollY;
        const deltaY = currentScrollY - lastScrollY;
        
        // Smoothly interpolate the scroll position
        smoothedScrollY += (currentScrollY - smoothedScrollY) * scrollEasing;
        
        // Update camera position based on scroll
        if (cameraRef.current) {
          cameraRef.current.position.y = 1.6 - (smoothedScrollY * 0.001);
        }
        
        // Update blob position
        if (blobRef.current) {
          blobRef.current.position.y = 2.5 - (smoothedScrollY * 0.001);
        }
        
        // Optional: Add scroll velocity to motion effects
        if (mouseVelocityRef.current) {
          mouseVelocityRef.current.y = deltaY * 0.00005; // Reduced influence
        }
        
        lastScrollY = currentScrollY;
        scrollRAF = null;
      });
    };

    // Mouse movement handler
    const handleMouseMove = (event) => {
      const newX = event.clientX;
      const newY = event.clientY;
      
      // Calculate velocity for motion effects
      mouseVelocityRef.current = {
        x: (newX - mouseRef.current.x) * 0.01,
        y: (newY - mouseRef.current.y) * 0.01
      };

      // Update camera position based on mouse X
      const normalizedX = (newX / window.innerWidth) * 2 - 1;
      const cameraX = normalizedX * 3;
      camera.position.x = THREE.MathUtils.lerp(camera.position.x, cameraX, 0.1);
      
      // Keep camera looking at center
      camera.lookAt(new THREE.Vector3(0, 2.5, -4));

      // Update DOF focus based on mouse Y position
      if (mouseDOFRef.current.enabled) {
        const normalizedY = ((newY / window.innerHeight) * 2 - 1);
        const midFocus = (mouseDOFRef.current.maxFocus + mouseDOFRef.current.minFocus) / 2;
        const focusRange = (mouseDOFRef.current.maxFocus - mouseDOFRef.current.minFocus) / 2;
        const targetFocus = midFocus + (normalizedY * focusRange);

        if (postProcessingRef.current) {
          postProcessingRef.current.updateDOF(THREE.MathUtils.lerp(
            postProcessingRef.current.passes.dof?.uniforms.focus.value || targetFocus,
            targetFocus,
            mouseDOFRef.current.smoothing
          ));
        }
      }

      // Store current position for next frame
      mouseRef.current = { x: newX, y: newY };
    };

    // Touch movement handler
    const handleTouchMove = (event) => {
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        handleMouseMove({
          clientX: touch.clientX,
          clientY: touch.clientY
        });
      }
    };

    // Add event listeners with passive option for better performance
    window.addEventListener('resize', handleResize, { passive: true });
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    window.addEventListener('touchmove', handleTouchMove, { passive: true });

    // Create stars BEFORE GUI initialization
    const stars = new Stars(scene, settingsRef.current);
    starsRef.current = stars;

    // Initialize GUI if debug mode
    const urlParams = new URLSearchParams(window.location.search);
    const showControls = urlParams.get('debug') === 'true';
    
    if (showControls) {
      guiRef.current = new BlobGUI({
        material: materialRef.current,
        ambientLight,
        directionalLight,
        pointLight,
        postProcessingRef,
        animationSettingsRef,
        effectOrderRef,
        layerTogglesRef,
        iorAnimationSettingsRef,
        mouseDOFRef,
        settingsRef,
        blob: blobRef.current,
        automatedMovementRef,
        starsRef: starsRef,
      });
    }

    // Function to generate random velocity
    const generateRandomVelocity = () => ({
      x: automatedMovementRef.current.minVelocity + 
         Math.random() * (automatedMovementRef.current.maxVelocity - automatedMovementRef.current.minVelocity),
      y: automatedMovementRef.current.minVelocity + 
         Math.random() * (automatedMovementRef.current.maxVelocity - automatedMovementRef.current.minVelocity)
    });

    // Function to simulate random movement
    const simulateRandomMovement = () => {
      if (!automatedMovementRef.current.enabled || automatedMovementRef.current.isActive) return;

      automatedMovementRef.current.isActive = true;
      
      const duration = automatedMovementRef.current.minDuration + 
                      Math.random() * (automatedMovementRef.current.maxDuration - automatedMovementRef.current.minDuration);
      let startTime = performance.now();
      
      const movements = Array.from(
        { length: automatedMovementRef.current.movementCount }, 
        () => generateRandomVelocity()
      );
      let currentMovementIndex = 0;

      const animate = () => {
        const currentTime = performance.now();
        const elapsed = currentTime - startTime;
        
        if (elapsed < duration) {
          const progress = (elapsed % (duration / movements.length)) / (duration / movements.length);
          const currentMovement = movements[currentMovementIndex];
          const nextMovement = movements[(currentMovementIndex + 1) % movements.length];
          
          mouseVelocityRef.current = {
            x: currentMovement.x + (nextMovement.x - currentMovement.x) * progress,
            y: currentMovement.y + (nextMovement.y - currentMovement.y) * progress
          };
          
          if (progress > 0.8) {
            currentMovementIndex = (currentMovementIndex + 1) % movements.length;
          }
          
          automatedMovementRef.current.duration = requestAnimationFrame(animate);
        } else {
          mouseVelocityRef.current = { x: 0, y: 0 };
          automatedMovementRef.current.isActive = false;
          automatedMovementRef.current.duration = null;
        }
      };

      automatedMovementRef.current.duration = requestAnimationFrame(animate);
    };

    // Set up interval for random movements
    const movementInterval = setInterval(() => {
      if (automatedMovementRef.current.enabled && !automatedMovementRef.current.isActive) {
        simulateRandomMovement();
      }
    }, automatedMovementRef.current.interval);

    // Add hot module replacement handling
    if (import.meta.hot) {
      import.meta.hot.accept(() => {
        // Clean up existing scene
        cleanup();
        // Force remount by updating key
        setMountKey(prev => prev + 1);
      });

      import.meta.hot.dispose(() => {
        cleanup();
      });
    }

    // Cleanup function
    const cleanup = () => {
      if (guiRef.current) {
        guiRef.current.destroy();
        guiRef.current = null;
      }
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      
      // Add checks before removing renderer DOM element
      if (containerRef.current && rendererRef.current?.domElement && 
          containerRef.current.contains(rendererRef.current.domElement)) {
        containerRef.current.removeChild(rendererRef.current.domElement);
      }

      // Dispose of Three.js resources
      if (geometry) geometry.dispose();
      if (materialRef.current) materialRef.current.dispose();
      if (rendererRef.current) rendererRef.current.dispose();
      if (postProcessingRef.current) {
        postProcessingRef.current.dispose();
      }
      
      // Clear intervals and animation frames
      clearInterval(movementInterval);
      if (automatedMovementRef.current?.duration) {
        cancelAnimationFrame(automatedMovementRef.current.duration);
      }
      if (starsRef.current) {
        starsRef.current.dispose();
      }
      
      // Reset all refs
      Object.keys(animationSettingsRef.current).forEach(key => {
        if (typeof animationSettingsRef.current[key] === 'object') {
          animationSettingsRef.current[key] = { ...animationSettingsRef.current[key] };
        }
      });
      
      // Reset other state
      mouseVelocityRef.current = { x: 0, y: 0 };
      mouseRef.current = { x: 0, y: 0 };
      frameCount.current = 0;
    };

    return cleanup;
  }, [settings.blobDetail, mountKey]);

  const randomizeSettings = useCallback(() => {
    // Material
    Object.assign(materialRef.current, {
      color: new THREE.Color(Math.random(), Math.random(), Math.random()),
      emissive: new THREE.Color(Math.random(), Math.random(), Math.random()),
      metalness: Math.random(),
      roughness: Math.random(),
      emissiveIntensity: Math.random() * 2,
      envMapIntensity: Math.random() * 3,
      clearcoat: Math.random(),
      clearcoatRoughness: Math.random(),
      sheen: Math.random(),
      sheenRoughness: Math.random(),
      sheenColor: new THREE.Color(Math.random(), Math.random(), Math.random()),
      iridescence: Math.random(),
      iridescenceIOR: 1 + Math.random() * 1.333,
      transmission: Math.random(),
      ior: 1 + Math.random() * 1.333,
      thickness: Math.random() * 5,
    });

    // Lighting
    ambientLightRef.current.intensity = Math.random() * 5;
    ambientLightRef.current.color.setHSL(Math.random(), 0.5, 0.5);

    directionalLightRef.current.intensity = Math.random() * 5;
    directionalLightRef.current.color.setHSL(Math.random(), 0.5, 0.5);
    directionalLightRef.current.position.set(
      (Math.random() - 0.5) * 10,
      1 + Math.random() * 4,
      -2 - Math.random() * 4
    );

    pointLightRef.current.intensity = Math.random() * 5;
    pointLightRef.current.color.setHSL(Math.random(), 0.5 + Math.random() * 0.5, 0.5 + Math.random() * 0.5);
    pointLightRef.current.position.set(
      (Math.random() - 0.5) * 10,
      1 + Math.random() * 4,
      -2 - Math.random() * 4
    );
    pointLightRef.current.distance = 5 + Math.random() * 15;
    pointLightRef.current.decay = 1 + Math.random();

    // Animation
    const newAnimation = {
      timeScale: 0.001 + Math.random() * 0.004,
      positionAmplitude: Math.random(),
      rotationSpeed: Math.random() * 2,
      heartbeat: {
        speed: Math.random() * 3,
        strength: Math.random(),
        sharpness: 0.1 + Math.random() * 4.9
      }
    };
    Object.assign(animationSettingsRef.current, newAnimation);

    // Noise
    const newNoise = {
      noiseScale1: Math.random() * 10,
      noiseScale2: Math.random() * 20,
      noiseScale3: Math.random() * 5,
      noiseInfluence1: Math.random() * 2,
      noiseInfluence2: Math.random() * 2,
      noiseInfluence3: Math.random() * 2,
      deformationStrength: Math.random(),
      timeScale1: 0.5 + Math.random() * 1.5,
      timeScale2: 0.3 + Math.random() * 1.2,
      timeScale3: 0.2 + Math.random() * 0.8,
      verticalBias: Math.random(),
      noiseAmplitude: Math.random() * 2
    };
    Object.assign(animationSettingsRef.current, newNoise);

    // Post-processing
    if (postProcessingRef.current) {
      const currentSettings = postProcessingRef.current.getSettings();
      postProcessingRef.current.updateSettings({
        ...currentSettings,
        rgbShift: {
          ...currentSettings.rgbShift,
          baseAmount: Math.random() * 0.01,
          motionMultiplier: Math.random() * 2.0
        },
        bloom: {
          ...currentSettings.bloom,
          strength: Math.random() * 3.0,
          radius: Math.random() * 1.5,
          threshold: Math.random() * 0.5
        },
        motionBlur: {
          ...currentSettings.motionBlur,
          intensity: Math.random() * 2,
          samples: 8 + Math.floor(Math.random() * 24),
          motionScale: Math.random() * 2,
          dampingFactor: 0.9 + Math.random() * 0.09
        },
        // glitch: {
        //   ...currentSettings.glitch,
        //   // Timing
        //   speed: 2 + Math.random() * 8, // Faster base speed
        //   glitchInterval: 0.05 + Math.random() * 0.5, // More frequent glitches
        //   glitchDuration: 0.05 + Math.random() * 0.3, // Longer glitch duration
        //   burstSpeed: 5 + Math.random() * 15, // Much faster bursts
        //   // Block glitch
        //   blockAmount: 0.5 + Math.random() * 0.5, // More intense blocks
        //   // blockSize: 0.05 + Math.random() * 0.45, // Larger blocks
        //   blockOffset: 1 + Math.random() * 9, // More extreme displacement
        //   blockThreshold: 0.2 + Math.random() * 0.3, // More frequent block glitches
        //   blockAlpha: 0.5 + Math.random() * 0.5, // More visible blocks
        //   rgbOffset: 1 + Math.random() * 4, // More extreme color splitting
        //   // Scanlines
        //   // scanlineAmount: 0.5 + Math.random() * 0.5, // More intense scanlines
        //   scanlineSize: 5 + Math.random() * 20, // Finer scanlines
        //   distortionAmount: 0.5 + Math.random() * 0.5 // More distortion
        // },
        wave: {
          ...currentSettings.wave,
          amplitude: Math.random() * 0.01,
          frequency: Math.random() * 20,
          speed: Math.random() * 5
        },
      });
    }

    // Automated movement
    const newMovement = {
      enabled: true,
      interval: 1000 + Math.random() * 9000,
      minDuration: 100 + Math.random() * 900,
      maxDuration: 1000 + Math.random() * 1000,
      minVelocity: -2 * Math.random(),
      maxVelocity: 2 * Math.random(),
      movementCount: 2 + Math.floor(Math.random() * 14)
    };
    Object.assign(automatedMovementRef.current, newMovement);
  }, []);

  // Add useEffect to listen for audio interaction
  useEffect(() => {
    const handleInteraction = () => setHasInteracted(true);
    window.addEventListener('click', handleInteraction);
    return () => window.removeEventListener('click', handleInteraction);
  }, []);

  return (
    <div key={mountKey}>
      <div 
        ref={containerRef} 
        className="relative w-full h-full"
      >
      </div>
      <TitleOverlay />
      <Cursor 
        hasInteracted={hasInteracted}
        onLongPressComplete={randomizeSettings}
      />
    </div>
  );
};

export default ARScene; 