import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header({ isPlaying, onToggleAudio, isGalleryOpen }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100); // Show text only in first 100px of scroll
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full z-[1500]">
      <div className="flex justify-between items-center p-8 text-white">
        <Link 
          to="/" 
          className="text-sm tracking-wider hover:opacity-70 transition-opacity"
        >
          DS—24
        </Link>
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onToggleAudio();
          }}
          className="relative flex items-center gap-3 flex-row-reverse focus:outline-none group
            p-2 md:p-3"
          aria-label="Toggle audio"
        >
          {/* Audio button content */}
          <div className="relative w-6 h-6">
            {/* Center dot */}
            <div 
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                w-1.5 h-1.5 rounded-full bg-white transition-all duration-300
                ${isPlaying ? 'opacity-100' : 'opacity-50 group-hover:opacity-100'}
              `}
            />
            
            {/* Ripple layers - only show when playing */}
            {isPlaying && (
              <>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
                  w-3 h-3 rounded-full bg-white/20 animate-ripple-1" />
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
                  w-3 h-3 rounded-full bg-white/20 animate-ripple-2" />
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
                  w-3 h-3 rounded-full bg-white/20 animate-ripple-3" />
              </>
            )}
            
            {/* Hover ring */}
            <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
              w-4 h-4 rounded-full border border-white/30 transition-all duration-300
              ${isPlaying ? 'opacity-100 scale-100' : 'opacity-0 scale-50 group-hover:opacity-100 group-hover:scale-100'}
            `} />
          </div>

          {/* Click to play text */}
          <span 
            className={`text-xs md:text-[10px] text-white tracking-widest uppercase
              transition-all duration-300 whitespace-nowrap
              ${(!isScrolled && !isPlaying) 
                ? 'opacity-50 group-hover:opacity-100 visible' 
                : 'opacity-0 invisible pointer-events-none'
              }`}
          >
            Click for audio
          </span>
        </button>
      </div>
    </header>
  );
}

export default Header;
