import * as THREE from 'three';
import { StarsShader } from '../shaders/stars/StarsShader';

export class Stars {
    constructor(scene, settings) {
        this.scene = scene;
        this.settings = {
            count: settings?.stars?.count ?? 1000,
            minSize: settings?.stars?.minSize ?? 0.5,
            maxSize: settings?.stars?.maxSize ?? 2.0,
            spread: settings?.stars?.spread ?? 100,
            twinkleSpeed: settings?.stars?.twinkleSpeed ?? 1.0,
            depth: settings?.stars?.depth ?? 50
        };

        this.uniforms = {
            time: { value: 0 },
            pixelRatio: { value: window.devicePixelRatio }
        };

        this.createStars();
    }

    createStars() {
        const geometry = new THREE.BufferGeometry();
        const positions = new Float32Array(this.settings.count * 3);
        const colors = new Float32Array(this.settings.count * 3);
        const sizes = new Float32Array(this.settings.count);

        for (let i = 0; i < this.settings.count; i++) {
            const i3 = i * 3;
            // Position
            positions[i3] = (Math.random() - 0.5) * this.settings.spread;
            positions[i3 + 1] = (Math.random() - 0.5) * this.settings.spread;
            positions[i3 + 2] = -this.settings.depth - Math.random() * this.settings.depth;

            // Color
            const color = new THREE.Color();
            color.setHSL(Math.random(), 0.5, 0.5 + Math.random() * 0.5);
            colors[i3] = color.r;
            colors[i3 + 1] = color.g;
            colors[i3 + 2] = color.b;

            // Size
            sizes[i] = this.settings.minSize + Math.random() * (this.settings.maxSize - this.settings.minSize);
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

        // Create material with our uniforms
        const material = new THREE.ShaderMaterial({
            vertexShader: StarsShader.vertexShader,
            fragmentShader: StarsShader.fragmentShader,
            uniforms: this.uniforms,
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthWrite: false
        });
        
        this.points = new THREE.Points(geometry, material);
        this.scene.add(this.points);
    }

    updateSettings(newSettings) {
        Object.assign(this.settings, newSettings);
        
        const positions = this.points.geometry.attributes.position.array;
        const colors = this.points.geometry.attributes.color.array;
        const sizes = this.points.geometry.attributes.size.array;

        for (let i = 0; i < this.settings.count; i++) {
            const i3 = i * 3;
            // Position
            positions[i3] = (Math.random() - 0.5) * this.settings.spread;
            positions[i3 + 1] = (Math.random() - 0.5) * this.settings.spread;
            positions[i3 + 2] = -this.settings.depth - Math.random() * this.settings.depth;

            // Color
            const color = new THREE.Color();
            color.setHSL(Math.random(), 0.5, 0.5 + Math.random() * 0.5);
            colors[i3] = color.r;
            colors[i3 + 1] = color.g;
            colors[i3 + 2] = color.b;

            // Size
            sizes[i] = this.settings.minSize + Math.random() * (this.settings.maxSize - this.settings.minSize);
        }

        this.points.geometry.attributes.position.needsUpdate = true;
        this.points.geometry.attributes.color.needsUpdate = true;
        this.points.geometry.attributes.size.needsUpdate = true;

        // Update twinkle speed if it was changed
        if (newSettings.twinkleSpeed !== undefined) {
            this.setTwinkleSpeed(newSettings.twinkleSpeed);
        }
    }

    setTwinkleSpeed(speed) {
        if (this.uniforms?.time) {
            this.uniforms.time.value = speed;
        }
    }

    update(time) {
        if (this.uniforms?.time) {
            this.uniforms.time.value = time;
        }
    }

    dispose() {
        if (this.points) {
            this.points.geometry.dispose();
            this.points.material.dispose();
            this.scene.remove(this.points);
        }
    }
} 