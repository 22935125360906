// Global configuration constants

export const MAX_CONTENT_WIDTH = '1280px';

// You can add other global constants here as needed
// For example:
// export const API_BASE_URL = 'https://api.example.com';
// export const DEFAULT_LANGUAGE = 'en';

export const projects = [
  {
    "id": "ranger-buddies-quest",
    "title": "Ranger Buddies Quest",
    "subtitle": "Interactive wildlife conservation experience for children",
    "type": "Interactive Experience",
    "image": "/images/projects/mandai/mandai-02.jpg",
    "additionalImages": [
      "/images/projects/mandai/mandai-01.jpg",
      "/images/projects/mandai/mandai-35.png",
      "/images/projects/mandai/mandai-25.png",
      "/images/projects/mandai/mandai-04.jpg",
    ],
    "description": "Ranger Buddies Quest transforms a visit to the Mandai Wildlife Reserve into an engaging educational adventure. Children use custom-designed smart binoculars to explore the zoo's 28-hectare grounds, delving into scientifically accurate content through a narrative-driven, park-wide gaming experience. The project promotes environmental conservation while encouraging outdoor exploration through immersive play.",
    "technologies": ["Custom Hardware", "Mobile App Development", "Interactive Storytelling", "Kinect", "UX Design"],
    "link": "https://rangerbuddies.com/en/home",
    "client": "Mandai Wildlife Reserve",
    "agency": "Media.Monks",
    "role": "Creative Director",
    "year": 2022,
    "objective": "To create an innovative park-wide experience that immerses children in conservation education through digital tools and interactive storytelling, transforming the Mandai Wildlife Reserve into a smart playground.",
    "challenge": "Mandai Wildlife Reserve, home to over 1000 animal species, sought to elevate the visitor experience by engaging children in conservation efforts through an interactive, educational, and playful approach. The challenge was to design an experience that captivates a digitally native generation while fostering a deeper connection with nature and wildlife.",
    "solution": "As Creative Director, I led the creative vision, working closely with multidisciplinary teams across UX, product design, content, and technology. We developed Ranger Buddies Quest—a first-of-its-kind experience featuring smart binoculars. Children used these binoculars to engage with the zoo's grounds, completing missions, unlocking learning points, and earning badges. The experience was enriched by narrative storytelling, with children taking on the role of Rangers guided by the ancient organisation COSCAS to protect wildlife. Physical outposts with interactive games, powered by touchscreens and Kinect, extended the educational play.",
    "execution": "I directed the integration of the binoculars, outposts, and mobile app, ensuring seamless user experience across digital and physical touchpoints. The binoculars, a custom hardware solution, enabled children to spot animals, navigate mini-games, and follow a mission-based narrative. Outposts at various points in the park provided additional interactive content, while the app for parents facilitated real-time tracking, mission control, and conversation starters.",
    "impact": "Ranger Buddies Quest transformed how children interact with wildlife, blending digital engagement with outdoor exploration. It became part of a nationwide education program, bringing conservation awareness to a new generation. The project received widespread recognition, winning several industry awards, including a People's Voice Webby Award in 2024, and continued to position the Mandai Wildlife Reserve as a leader in conservation education.",
    "awards": [
      "Webby Awards 2024 – People's Voice Winner",
      "Adfest – Mobile Lotus, Bronze",
      "Marketing Awards 2024 – Silver x 4",
      "Event Marketing Awards 2024 – Best Gamification, Bronze",
      "Spikes 2024 – Shortlists x 2",
      "ADC – Finalist"
    ],
    "collaborators": [
      "Bobby Tay",
      "Mustapha Zainal",
      "Daniel Samson",
      "Patricio Lobos",
      "AS Anam",
      "Vanessa Lim",
      "Huang Yizhen",
      "Joanna Lim",
      "Keith Tang",
      "Kayla Chia",
      "Delano Kamp",
      "Khin Thapyay",
      "Alireza Sadeh",
      "Emma Gilpin"
    ],
    "hidden": false
  },
  {
    "id": "coca-cola-tomorrowland",
    "title": "Tomorrowland Experience",
    "subtitle": "Unlocking interactive experiences through Coca-Cola cans",
    "type": "Immersive Web",
    "image": "/images/projects/tomorrowland/tomorrowland-06-static.jpg",
    "gif": "/images/projects/tomorrowland/tomorrowland-06.gif",
    "additionalImages": [
      "/images/projects/tomorrowland/tomorrowland-07.gif",
      "/images/projects/tomorrowland/tomorrowland-01.png",
    ],
    "description": "The Tomorrowland Experience used Coca-Cola cans to unlock three unique, interactive worlds, providing users with immersive digital adventures tied to the iconic festival. One of these worlds allowed users to create their own DJ music, while the others offered different explorative and engaging experiences that combined music, visuals, and interaction, extending the magic of Tomorrowland beyond the festival grounds.",
    "technologies": ["WebGL", "Audio Interface", "Interactive Music Creation", "Immersive Storytelling"],
    "link": "",
    "client": "Coca-Cola",
    "agency": "Subvrsive",
    "role": "Creative Director",
    "year": 2024,
    "objective": "To enhance Coca-Cola's association with Tomorrowland by creating an immersive, multi-world digital experience that engages users globally, blending music, interaction, and exploration.",
    "challenge": "Coca-Cola wanted to offer a memorable, interactive digital experience that resonated with both Tomorrowland attendees and global music fans. The challenge was to design an experience that provided diverse, engaging content while remaining easy to access through mobile devices.",
    "solution": "As Creative Director, I led the project to design three distinct interactive worlds, unlocked by scanning Coca-Cola cans. One world was focused on DJ music creation, allowing users to mix tracks and control live visuals. The other two worlds offered equally engaging experiences tied to the festival, with a mix of interactive elements and immersive storytelling. Each world offered a unique experience that kept users entertained and connected to both the Coca-Cola and Tomorrowland brands.",
    "execution": "The experience was developed using WebGL to power interactive 3D environments, alongside custom-built audio and visual interfaces. Users accessed the experience by scanning their Coca-Cola cans, unlocking a portal to one of three worlds. Each world provided a different thematic experience—one allowed users to create DJ mixes with real-time visual feedback, while the others offered music-driven exploration and storytelling. I worked closely with the design and development teams to ensure each world was visually captivating and seamlessly interactive.",
    "impact": "The Tomorrowland Experience significantly enhanced Coca-Cola's engagement with both festival-goers and a global audience, creating deeper brand connections through immersive, interactive content. The project set a new standard for multi-world interactive experiences, blending music, visuals, and storytelling to engage users on a deeper level.",
    "awards": [],
    "collaborators": [],
    "hidden": false
  },
  {
    "id": "evian-live-the-journey",
    "title": "Live The Journey",
    "subtitle": "Bringing the beauty of the Alps to grounded travellers",
    "type": "Immersive Web",
    "image": "/images/projects/evian/evian-09-static.jpg",
    "gif": "/images/projects/evian/evian-09.gif",
    "additionalImages": [
      "/images/projects/evian/evian-06.png",
      "/images/projects/evian/evian-11.gif",
      "/images/projects/evian/evian-12.gif",
      "/images/projects/evian/evian-18.png"
    ],
    "description": "We brought Evian's 15-year water purification journey to life with a WebAR experience, connected to custom-designed bottles. Each bottle unlocked an immersive 3D narrative, allowing users to explore the journey of Evian water through the French Alps. The experience blended nostalgic, hand-drawn illustrations inspired by vintage travel posters with modern WebGL and AR technology, offering a unique digital escape during the pandemic.",
    "technologies": ["WebAR", "3D Narrative", "Unity", "WebGL"],
    "link": "",
    "client": "Evian",
    "agency": "Media.Monks",
    "role": "Creative Director",
    "year": 2021,
    "objective": "To launch Evian's limited edition bottles with an engaging, immersive digital experience that highlights the 15-year journey of Evian water, blending nostalgia and modern technology.",
    "challenge": "With global travel restrictions due to the pandemic, Evian needed a way to bring the beauty of the French Alps to their audience. The challenge was to create an immersive experience connected to custom-designed bottles that echoed the timeless journey of the water and resonated with consumers stuck at home.",
    "solution": "We developed custom-designed bottles, each linked to an interactive WebAR experience, allowing users to scan the bottle to unlock unique 3D stories about Evian's 15-year journey through the Alps. As the creative lead in a multidisciplinary team, I guided the overall concept and visual direction, ensuring the nostalgic travel poster style blended seamlessly with modern technology. The campaign used organic, hand-drawn brush strokes combined with AR to transport users to the Alps in a digital escape.",
    "execution": "The experience was built using WebGL and Unity, with line art and watercolour animations bringing the water's journey to life. I collaborated closely with UX designers, developers, and animators to ensure the creative vision was executed across all touchpoints. Additionally, I directed the voiceover in English, Mandarin, and Korean to tailor the experience for each market. The campaign rolled out in Singapore, South Korea, Taiwan, and Hong Kong, tying the physical bottles to the immersive WebAR experience.",
    "impact": "The campaign exceeded sales targets by 7% and provided a digital escape to the French Alps. The custom-designed bottles, each tied to a unique AR story, deepened user engagement and created a memorable experience for grounded travellers during lockdown. The project was also recognised with an FWA award.",
    "awards": ["FWA Winner"],
    "collaborators": [],
    "hidden": false
  },
  {
    "id": "expedium-experience",
    "title": "Expedium Verse® Experience",
    "subtitle": "Immersive digital platform for medical marketing",
    "type": "Immersive Web",
    "image": "/images/projects/expedium/expedium-05-static.jpg",
    "gif": "/images/projects/expedium/expedium-05.gif",
    "additionalImages": [
      "/images/projects/expedium/expedium-02.gif",
      "/images/projects/expedium/expedium-06.gif",
      "/images/projects/expedium/expedium-07.gif",
      "/images/projects/expedium/expedium-13.gif"
    ],
    "description": "Experience the design excellence of a life-changing device through an interactive 3D product page and immersive AR. Bringing medical marketing into the digital age, we engaged surgeons around Expedium Verse® and elevated Johnson & Johnson's brand perception as innovators.",
    "technologies": ["AR", "3D Product Page", "Immersive Web Development"],
    "link": "",
    "client": "Johnson & Johnson Medical",
    "agency": "Media.Monks",
    "role": "Creative Director",
    "year": 2022,
    "objective": "To launch the Expedium Verse® Fenestrated Screw in Japan with an innovative digital experience, engaging surgeons and highlighting the device's construction and functionality.",
    "challenge": "Traditional, face-to-face medical marketing was no longer enough to effectively communicate the benefits of advanced medical devices. The challenge was to create a digital experience that could engage surgeons in an immersive, informative way.",
    "solution": "We turned traditional medical marketing on its head by designing and developing an interactive 3D product page and AR experience that demonstrated the construction and functionality of the Expedium Verse® Fenestrated Screw. Surgeons could explore the device in detail through 3D visualisations and AR, gaining a deeper understanding of its design and impact on patient mobility.",
    "execution": "I led the creative direction and worked closely with the development team to build an intuitive and engaging platform. The 3D product page and AR experience were designed to be accessible on both desktop and mobile, allowing surgeons to interact with the device virtually. By enabling users to rotate, zoom in, and explore key aspects of the device, we created a dynamic and engaging experience that helped bridge the gap between product and professional.",
    "impact": "The digital experience increased on-site engagement by 58%, with surgeons spending an average of five minutes in the AR feature. This customer-centric approach not only improved understanding of the product but also elevated Johnson & Johnson's brand perception as an industry innovator, proving the value of digital-first medical marketing.",
    "awards": [],
    "collaborators": [],
    "hidden": false
  },

  {
    "id": "nike-air-max-270-react",
    "title": "Nike Air Max 270 React Campaign",
    "subtitle": "Immersive Instagram experience for product launch",
    "type": "Social Campaign",
    "image": "/images/projects/nike/nike-02.jpg",
    "additionalImages": [
      "/images/projects/nike/nike-14.jpg",
      "/images/projects/nike/nike-04.jpg",
      "/images/projects/nike/nike-13.jpg",
      "/images/projects/nike/nike-07.jpg"
    ],
    "description": "The Nike Air Max 270 React campaign was an immersive Instagram experience designed to engage a younger, sports-driven audience. Through a series of interactive ‘Nike worlds,’ we showcased the shoe's unique design and functionality, offering users a fun and exploratory way to engage with the product and the brand.",
    "technologies": ["Instagram Integration", "Interactive Storytelling", "3D Modelling", "Social Campaign"],
    "link": "",
    "client": "Nike",
    "agency": "B-Reel",
    "role": "Creative Lead",
    "year": 2019,
    "objective": "To create a dynamic and engaging social campaign for the launch of the Nike Air Max 270 React, using immersive Instagram experiences to connect with younger audiences.",
    "challenge": "Nike wanted a fresh and playful approach to launching the Air Max 270 React, targeting a younger demographic who engage heavily with social platforms. The challenge was to build a campaign that would highlight the shoe’s design and performance features while leveraging the power of Instagram as an interactive medium.",
    "solution": "As Creative Lead, I co-led the creation of six immersive ‘Nike worlds,’ each representing a different aspect of the shoe's design and cultural influences. I 3D modelled the entire shoe and directed the art for the 3D teams, ensuring the visuals aligned with the brand’s aesthetic. Through extensive research, we developed distinctive concepts for each world, capturing the unique essence of both the product and the environment it was set in. We utilised Instagram’s interactive features to create a highly engaging experience, combining videos, stories, and easter eggs for users to explore.",
    "execution": "The campaign revolved around six Instagram ‘worlds,’ each with a distinct look and feel tied to the Air Max 270 React’s creative inspirations. These worlds featured interactive stories, video content, and hidden surprises, all designed to encourage exploration. I not only provided creative direction but also worked closely with the 3D teams to bring the shoe to life in the digital space. The seamless Instagram integration ensured that users could engage with the worlds easily while immersing themselves in the experience.",
    "impact": "The campaign successfully captured Nike’s target audience, generating high levels of engagement and positive feedback. It elevated the brand’s presence on Instagram by offering a fun, interactive experience that aligned with the lifestyle of younger consumers. The success of the campaign demonstrated the power of social media as a platform for immersive storytelling and product discovery.",
    "awards": [],
    "collaborators": [],
    "hidden": false
  },
  {
    "id": "richie-hawtin-closer",
    "title": "Closer DJ Experience",
    "subtitle": "Interactive app for live-streaming DJ performances",
    "type": "App",
    "image": "/images/projects/richie-hawtin/richie-hawtin-09-static.jpg",
    "gif": "/images/projects/richie-hawtin/richie-hawtin-09.gif",
    "additionalImages": [
      "/images/projects/richie-hawtin/richie-hawtin-06.jpg",
      "/images/projects/richie-hawtin/richie-hawtin-05.jpg",
      "/images/projects/richie-hawtin/richie-hawtin-11.gif",
      "/images/projects/richie-hawtin/richie-hawtin-12.gif"
    ],
    "description": "As Creative Lead at B-Reel, I co-led the development of CLOSER, an interactive audiovisual app that allows users to deconstruct Richie Hawtin's DJ performances. The app offers users the ability to toggle between multiple camera angles, control audio layers, and explore his creative process in real-time. CLOSER breaks new ground in live music by letting users interact with both audio and video feeds, gaining unprecedented insight into the complexity of Hawtin's performances.",
    "technologies": ["Live Streaming", "Multimedia App Development", "Interactive UI"],
    "link": "https://www.theverge.com/2019/12/20/21029821/richie-hawtin-closer-app-dj-concert-techno-audio-visual",
    "client": "Richie Hawtin",
    "agency": "B-Reel",
    "role": "Creative Lead",
    "year": 2019,
    "objective": "To create an innovative, interactive app that allows users to experience Richie Hawtin's DJ performances in a completely new way, offering transparency into his live set creation.",
    "challenge": "Traditional concert videos fail to capture the intricate details of a DJ's performance. Richie Hawtin wanted to create an interactive experience that allows fans to explore and understand the complexity of his shows by controlling different audio and visual elements.",
    "solution": "We designed CLOSER, a highly interactive app with three stacked screens that provide multiple ways to engage with Hawtin's performances. Users can toggle individual sound layers on and off, switch between various camera angles, and closely follow his hand movements across the equipment. This allows fans to deconstruct his performances, offering a more personal and exploratory experience.",
    "execution": "The app features an overhead shot of Hawtin's setup, allowing users to control audio elements like decks, effects, synths, and drums. They can also switch between audience views and close-ups of specific equipment, giving each user a unique experience. Built using cutting-edge multimedia and live-streaming technologies, CLOSER provides seamless, real-time interaction without compromising on quality.",
    "impact": "CLOSER redefined what a live concert video could be, offering fans a deeper connection with Hawtin's creative process. The app inspired curiosity, letting users explore different facets of the performance at their own pace. It also set a new standard for interactive music experiences, allowing fans to remix and reinterpret live performances in real-time. Richie Hawtin's vision of combining technology with music was fully realised, establishing CLOSER as a pioneering example of innovation in the entertainment industry.",
    "awards": [],
    "hidden": false
  },
  {
    id: "london-mithraeum",
    title: "London Mithraeum",
    subtitle: "Digital companion for exploring Roman artefacts",
    type: "Digital Exhibit",
    image: "/images/projects/mithraeum/mithraeum-01.jpg",
    additionalImages: [
      "/images/projects/mithraeum/mithraeum-02.jpg",
      "/images/projects/mithraeum/mithraeum-04.jpg"
    ],
    description: "As Lead Creative Technologist at AllofUs, I co-led the development of a digital companion for the London Mithraeum within Bloomberg's new European HQ. The project brought Roman artefacts to life through interactive animated stories, allowing visitors to explore the cultural and technological advancements of Roman Londoners. The experience was designed to make history more accessible and engaging by connecting artefacts with animated narratives.",
    technologies: ["Interactive Storytelling", "Digital Exhibit", "Prototyping"],
    link: "https://www.londonmithraeum.com/",
    client: "Bloomberg & Museum of London",
    agency: "AllofUs",
    role: "Lead Creative Technologist",
    year: 2017,
    objective: "To create an engaging digital companion that makes the artefacts and history of Roman London accessible and interactive for visitors to the London Mithraeum.",
    challenge: "How to tell compelling stories around the artefacts discovered at the London Mithraeum and make the ancient history of Roman London engaging for modern visitors.",
    solution: "We created an interactive digital experience that used animations and interactive stories to bring selected Roman artefacts to life, allowing visitors to explore the technological and cultural context of the time.",
    execution: "Each artefact was carefully selected and paired with an interactive animated story. The digital companion provided a detailed guide for visitors, helping them explore Roman life and technological advancements.",
    impact: "The digital companion enhanced visitor engagement, making history accessible and captivating through the use of modern technology and interactive storytelling, connecting the past with the present.",
    awards: [],
    hidden: false
  },
  {
    id: "pests-and-my-planet",
    title: "Pests & My Planet",
    subtitle: "Educational VR game on climate change and vector-borne diseases",
    type: "Virtual Reality",
    image: "/images/projects/pests/pests-01.jpg",
    additionalImages: [
      "/images/projects/pests/pests-02.jpg",
      "/images/projects/pests/pests-03.jpg"
    ],
    description: "As Creative Director at Subvrsive, I led the development of 'Pests & My Planet,' a VR game launched at COP28. The game educates policymakers about the environmental risks of climate change and its impact on vector-borne diseases. After COP28, the game was adapted for school students, available on tablets and mobile phones, to extend its educational reach to younger generations.",
    technologies: ["VR", "Interactive Gameplay", "Tablet and Mobile Adaptation"],
    link: "",
    client: "Arctech",
    agency: "Subvrsive",
    role: "Creative Director",
    year: 2023,
    objective: "To create an engaging and educational VR game that informs policymakers about the impact of climate change on vector-borne diseases, while promoting informed decision-making.",
    challenge: "Communicating the complex relationship between climate change and vector-borne diseases in an engaging and understandable way for both policymakers and students.",
    solution: "We developed an interactive VR game that features environmental risk scenarios, where users engage with various aspects of climate change. The content was later adapted into a tablet and mobile version for school students, simplifying the scientific concepts for younger audiences.",
    execution: "The game featured interactive scenarios and dynamic graphics, immersing users in climate-related risks and encouraging informed decision-making. The school adaptation made the content more accessible, ensuring the message reached pre-teens and teens.",
    impact: "'Pests & My Planet' became an effective tool for educating both policymakers and students on climate change, extending its influence to future generations and empowering them with vital environmental knowledge.",
    awards: [],
    hidden: true
  },
  {
    id: "scalp-quest-vr",
    title: "Scalp Quest VR",
    subtitle: "Virtual reality experience for educating beauty influencers",
    type: "Virtual Reality",
    image: "/images/projects/scalp-quest/scalp-quest-04.gif",
    additionalImages: [
      "/images/projects/scalp-quest/scalp-quest-01.jpg",
      "/images/projects/scalp-quest/scalp-quest-03.jpg"
    ],
    description: "As Creative Director at Media.Monks, I led the development of a VR experience for P&G's Singapore Innovation Center to educate beauty influencers and CEOs about dandruff treatments. The experience takes users into a detailed exploration of a scalp affected by dandruff and incorporates haptic feedback to simulate itchiness until effective treatments are found.",
    technologies: ["VR", "Haptic Feedback", "Interactive Gameplay"],
    link: "",
    client: "P&G - SGIC",
    agency: "Media.Monks",
    role: "Creative Director",
    year: 2021,
    objective: "Create a VR experience to educate influencers and CEOs about dandruff treatments through immersive technology.",
    challenge: "Making a topic like dandruff more engaging and immersive for an audience of beauty influencers and executives.",
    solution: "We created a VR experience where users explore a scalp environment and test different treatments through interactive gameplay, with haptic feedback simulating the sensation of itchiness.",
    execution: "The VR experience included interactive gameplay with dynamic graphics and vibrating controllers, allowing users to feel the scalp's itchiness until relief was found through the correct treatment.",
    impact: "This VR approach transformed product education into an engaging, empathetic experience that vividly conveyed the effectiveness of dandruff treatments.",
    awards: [],
    hidden: false
  },
  {
    "id": "convexity-experience",
    "title": "Convexity Experience",
    "subtitle": "Interactive recruitment tool based on company principles",
    "type": "Immersive Web",
    "image": "/images/projects/convexity/convexity-05-static.jpg",
    "gif": "/images/projects/convexity/convexity-05.gif",
    "additionalImages": [
        "/images/projects/convexity/convexity-04.gif",
        "/images/projects/convexity/convexity-03.jpg"
    ],
    "description": "We transformed Convexity's core principles into an interactive WebGL experience, allowing job applicants to explore the company's values through a star constellation interface. This unique recruitment tool served as both a storytelling platform and an engaging filter for potential hires, making the application process more memorable and dynamic.",
    "technologies": ["WebGL", "Interactive Storytelling", "Immersive Web Development"],
    "link": "",
    "client": "Convexity",
    "agency": "Media.Monks",
    "role": "Creative Director",
    "year": 2020,
    "objective": "To create an interactive digital experience that translates Convexity's company principles into a dynamic, engaging recruitment tool.",
    "challenge": "Recruitment tools are often static and fail to fully convey a company's culture or values. The challenge was to transform Convexity's 13 core principles into an engaging, interactive experience that could attract the right candidates while providing an insightful exploration of the company's ethos.",
    "solution": "As Creative Director, I led the design and development of an immersive WebGL experience that represented each of Convexity's principles as a star in a constellation. Users could explore these principles interactively, allowing them to engage with the company's values in a visually compelling way. This approach allowed Convexity to convey their culture more effectively while serving as a recruitment filter to attract candidates aligned with their ethos.",
    "execution": "We built the experience using WebGL, creating an interactive star constellation interface where each star represented a core principle. Users could click on each star to learn more about the value it represented, with dynamic animations guiding them through the exploration. The platform was designed to be simple yet immersive, allowing applicants to interact directly with the company's values and better understand Convexity's unique work culture. I worked closely with the design and development teams to ensure the experience was intuitive and engaging.",
    "impact": "The Convexity WebGL experience elevated their recruitment process, making it more engaging and reflective of the company's innovative spirit. It attracted candidates who were genuinely interested in the company's values, leading to more meaningful applications and a stronger alignment between potential hires and the company culture.",
    "awards": ["FWA of the Day"],
    "collaborators": [],
    "hidden": false
  },
  {
    "id": "our-planet",
    "title": "Our Planet",
    "subtitle": "Interactive digital platform for environmental education",
    "type": "Immersive Web",
    "image": "/images/projects/our-planet/our-planet-03.jpg",
    "additionalImages": [
      "/images/projects/our-planet/our-planet-19.png",
      "/images/projects/our-planet/our-planet-25.png"
    ],
    "description": "For the launch of the Emmy-winning Netflix and WWF series 'Our Planet,' we created a digital platform that extended the series' environmental message far beyond streaming. The platform allowed users to explore the seven habitats featured in the series, providing insights into biodiversity and pressing environmental challenges. Visitors could engage with interactive features like an explorable globe, educational resources, and a pledge system to take action for climate justice.",
    "technologies": ["WebGL", "Interactive Storytelling", "Data Visualisation", "UI/UX Design"],
    "link": "http://ourplanet.com/",
    "client": "WWF & Netflix",
    "agency": "B-Reel",
    "role": "Creative Lead",
    "year": 2019,
    "objective": "To create an engaging digital experience that extends the impact of 'Our Planet,' encouraging users to learn about environmental issues and take action.",
    "challenge": "Our task was to amplify the message of 'Our Planet' by creating a platform that would immerse users in the environmental challenges presented in the series. The goal was to educate and inspire action while providing a seamless connection with the streaming experience on Netflix.",
    "solution": "I led the creation of the platform, designing the user experience to be centred around the seven habitats featured in the series. The explorable globe allowed users to interact with the content and discover environmental challenges in different parts of the world. We also integrated educational resources for schools and created the 'Voice for the Planet' initiative, allowing users to pledge their voice for climate justice.",
    "execution": "We designed an immersive WebGL experience that included interactive storytelling, behind-the-scenes footage, infographics, and the ability to explore different habitats. The 'Voice for the Planet' initiative allowed visitors to add their pledge to an interactive globe, visualising global support for climate action. The platform was the first of its kind to connect directly with Netflix, allowing for a more integrated experience.",
    "impact": "In the first three weeks after launch, the platform had over 1.5 million visits, with 110,000 users completing the explorable globe feature. The 'Voice for the Planet' campaign saw a 70% conversion rate, with 50,500 sign-ups and 370,000 clicks to WWF's website. The project helped position 'Our Planet' as not just a documentary but a movement, engaging users to take direct action in environmental conservation.",
    "awards": [],
    "collaborators": [],
    "hidden": false
  },
  {
    id: "red-bull-global-design-system",
    title: "Design System",
    subtitle: "Unified design system for a fragmented media ecosystem",
    type: "Product Design",
    image: "/images/projects/redbull/redbull-01.jpg",
    additionalImages: [
      "/images/projects/redbull/redbull-02.jpg",
      "/images/projects/redbull/redbull-03.jpg"
    ],
    description: "As Creative Lead at B-Reel, I directed the creation of a new design language and unified design system for Red Bull, streamlining their fragmented ecosystem of microsites, products, and platforms. The project involved creating a flexible design system that worked across 64 variations of Red Bull's .com experience, designed to serve different audiences in multiple languages while maintaining consistency across the brand's diverse properties.",
    technologies: ["Design System", "Atomic Design", "Web Development"],
    link: "http://redbull.com",
    client: "Red Bull",
    agency: "W12 Studios",
    role: "Creative Lead",
    year: 2016,
    objective: "To create a unified design system for Red Bull's fragmented digital presence across microsites, products, and platforms, ensuring consistency while maintaining flexibility for various markets.",
    challenge: "Red Bull's diverse brand offerings, ranging from sports and culture to music and adventure, had led to a fragmented media landscape across multiple digital properties, each with its own design language.",
    solution: "We developed a new design system using an **atomic design philosophy**, which allowed us to build modular components that could be easily adapted across different platforms. The system had to work across 64 variations of Red Bull's .com experience, supporting different languages and target audiences.",
    execution: "The design system was built to be highly flexible, with modular cards and structures that could be combined in various ways to serve different content needs, while maintaining a cohesive brand identity.",
    impact: "The unified design system helped streamline Red Bull's digital presence, creating a more consistent and cohesive experience for users across different platforms and properties, while still allowing for localisation and flexibility.",
    awards: [],
    hidden: false
  },
  {
    "id": "vimeo-streaming-product-vision",
    "title": "Streaming Product Vision",
    "subtitle": "Exploring the future of Vimeo as a streaming video platform",
    "type": "Product Design Exploration",
    "image": "/images/projects/vimeo/vimeo-02.jpg",
    "additionalImages": [
      "/images/projects/vimeo/vimeo-05.jpg",
      "/images/projects/vimeo/vimeo-06.jpg"
    ],
    "description": "This project was part of Vimeo’s exploration into transitioning from a creator-focused platform to a subscription video-on-demand (SVOD) model. The future vision for Vimeo aimed to balance their roots in independent creator content with a more cinematic, high-quality streaming platform. This exploration helped Vimeo rethink their product offering and their position in the competitive streaming market.",
    "technologies": ["Product Vision", "UI/UX Design", "Prototyping"],
    "client": "Vimeo",
    "agency": "W12 Studios",
    "role": "Creative Technologist",
    "year": 2016,
    "objective": "To explore Vimeo’s future as a streaming video platform, designing a vision that could support both subscription video-on-demand (SVOD) and independent creator content.",
    "challenge": "Vimeo wanted to expand into the subscription video market while maintaining their commitment to their core community of creators. The challenge was to envision a platform that balanced these two priorities without alienating either audience.",
    "solution": "As Creative Technologist at W12 Studios, I co-led the development of Vimeo’s future product vision. We designed a platform model that introduced a new channel-based structure for both SVOD and creator content. The project included fully prototyping an intuitive interface that featured enhanced video previews, a more cinematic look, and the ability for users to discover content in a way that aligned with Vimeo’s creative brand.",
    "execution": "We developed fully functioning prototypes of the interface, allowing users to switch seamlessly between channels, view high-quality video previews, and explore curated content. As part of the multidisciplinary team, I worked closely with designers and developers to ensure every part of the interface was interactive and user-friendly. This exploration helped Vimeo test how their platform could evolve to support both independent creators and premium content.",
    "impact": "Though not a commercial release, the Vimeo Streaming Vision helped shape Vimeo’s product strategy as they explored ways to evolve from a creator-first platform to a broader streaming service. The project provided Vimeo with valuable insights into balancing their creator community with the growing demand for premium content, influencing their future platform development.",
    "awards": [],
    "collaborators": [],
    "hidden": true
  },
  {
    "id": "samsung-decor-tv",
    "title": "Decor TV",
    "subtitle": "Transforming televisions into high-end ambient design features",
    "type": "Product Design",
    "image": "/images/projects/samsung/samsung-13.jpg",
    "additionalImages": [
      "/images/projects/samsung/samsung-01.jpg",
      "/images/projects/samsung/samsung-06.jpg",
      "/images/projects/samsung/samsung-08.jpg",
      "/images/projects/samsung/samsung-02.jpg"
      
    ],
    "description": "Samsung Decor TV was part of an internal design exploration aimed at reimagining how screens could enhance contemporary interiors. The project focused on transforming televisions into ambient design features that display curated visuals when idle, integrating advanced technology with high-end interior design.",
    "technologies": ["Ambient Display", "UI/UX Design", "Product Design"],
    "link": "",
    "client": "Samsung",
    "agency": "W12 Studios",
    "role": "Creative Technologist",
    "year": 2016,
    "objective": "To prototype and explore future concepts for Samsung, focusing on turning televisions into high-end ambient displays that blend seamlessly into contemporary living spaces.",
    "challenge": "The challenge was to push the boundaries of what a television could be, transforming it from a purely functional device into a design-driven feature that complements modern interiors. We needed to develop a fully working prototype that demonstrated the feasibility of this vision.",
    "solution": "As a Creative Technologist, I co-led the project, focusing on designing and prototyping the entire interface to ensure it was functional and user-friendly. The 'living surface' concept was central to the design, enabling the TV to display ambient visuals when not in use. We collaborated closely with UI/UX designers and developers to create a seamless, fully working prototype of the interface.",
    "execution": "We prototyped the full interface, ensuring every part of the design worked as intended. Users could customise their ambient display with a range of visuals, from abstract art to nature scenes, turning the TV into a part of the room’s aesthetic. I worked closely on the technical aspects, ensuring the interface was fully operational and integrated with the hardware, creating a functional prototype that demonstrated the potential of the concept.",
  "impact": "Though not commercially released, Samsung Decor TV served as a key exploration into how technology could blend with interior design, influencing future product development. The fully working prototype showcased the viability of the concept, contributing to Samsung's long-term vision for integrating technology seamlessly into home environments.",
  "awards": [],
    "collaborators": []
  },
  {
    "id": "kia-com-design-system",
    "title": "Global Design System",
    "subtitle": "An immersive, scalable experience for a global audience",
    "type": "Product Design",
    "image": "/images/projects/kia/kia-24.png",
    "additionalImages": [
      "/images/projects/kia/kia-22.png",
      "/images/projects/kia/kia-01.png"
    ],
    "description": "As part of Kia's global rebrand, we developed a comprehensive design system for Kia.com, creating a personalised and immersive digital journey. The new design system unified the experience across five key regions, each with unique business requirements, and ensured scalability for multiple languages and markets. This project aligned with Kia’s new brand premise, 'Movement that Inspires,' integrating personalised journeys from pre-purchase to post-purchase phases.",
    "technologies": ["Design System", "UX Design", "Global Scalability", "Modular Components", "Commerce"],
    "client": "Kia Global",
    "agency": "Media.Monks",
    "role": "Creative Director",
    "year": 2021,
    "objective": "To create a flexible and immersive global design system that reflects Kia's rebrand, ensuring a personalised user experience while meeting the diverse needs of multiple regions.",
    "challenge": "Kia needed a design system that worked across five regions, each with its own requirements, while supporting multiple languages and maintaining a unified brand identity.",
    "solution": "Leading the project from pitch to execution, I collaborated with a multidisciplinary team to create a modular design system. This system allowed each region to customise their content while keeping the overall experience consistent with Kia’s new brand identity. Our approach was driven by thorough research, including competitor analysis and user surveys, to ensure the design met the diverse needs of Kia's global audience.",
    "execution": "We developed 92 page templates that could be adapted for different markets. My role involved guiding the design process, aligning teams, and ensuring seamless communication between design and development. I also led client workshops to review key decisions and ensure the vision was understood and aligned. The system's flexibility allowed regions to tailor the platform to local needs without compromising the global brand.",
    "impact": "The design system unified Kia's global presence while giving local teams the flexibility to adapt the platform for their markets. This scalable solution provided a personalised, seamless user experience from exploration to purchase, in line with Kia’s new brand ethos, 'Movement that Inspires.'",
    "awards": [],
    "collaborators": [
      "Sally Kim",
      "Sophia McDermott",
      "Vanessa Lim",
      "Huang Yizhen",
      "Yooin Choo",
      "SC Wong",
      "Doyeon Lee",
      "Yujin Joung",
      "Keith Tang",
      "Royston Phang",
      "Kayla Chia",
      "Sarah Lew"
    ]
  },
  {
    "id": "here-urban-mobility-index",
    "title": "Urban Mobility Index",
    "subtitle": "A data-driven interactive platform for visualising urban mobility",
    "type": "Product Design",
    "image": "/images/projects/here/here-03.jpg",
    "additionalImages": [
      "/images/projects/here/here-01.gif",
      "/images/projects/here/here-02.gif",
    ],
    "description": "The HERE Urban Mobility Index is an interactive data platform that visualises urban mobility insights across global cities, providing a comprehensive look at transport efficiency, infrastructure, and sustainability. The platform helps city planners, policymakers, and the public make informed decisions to improve mobility in urban areas.",
    "technologies": ["Data Visualisation", "Interactive Web", "Urban Analytics"],
    "link": "https://urbanmobilityindex.here.com",
    "client": "HERE",
    "agency": "B-Reel",
    "role": "Creative Director",
    "year": 2023,
    "objective": "To develop a visually engaging, data-driven platform that allows users to explore and compare mobility data across global cities, highlighting key insights for improving urban transportation systems.",
    "challenge": "HERE Technologies needed a way to communicate complex mobility data in an engaging and accessible manner. The challenge was to create a platform that could present dense data sets while keeping the user experience intuitive, allowing for easy comparison between cities on key urban mobility metrics.",
    "solution": "As the Creative Lead, I led the development of a clear, visually compelling interface that made large data sets easy to explore. We used dynamic data visualisation tools to present key metrics such as public transportation efficiency, sustainability, and infrastructure readiness. The design focused on intuitive navigation and interactive elements that let users compare cities side-by-side and drill down into specific mobility metrics.",
    "execution": "We designed the platform with a focus on clean, informative data visualisations that users could interact with in real-time. The interface allowed users to filter data by city, transport type, and sustainability metrics. I worked closely with data analysts, developers, and designers to ensure that the visualisation of the data was both accurate and engaging. The platform also included a city ranking system, highlighting the top performers in urban mobility.",
    "impact": "The HERE Urban Mobility Index provided city planners and policymakers with valuable insights to inform decisions about future transport infrastructure and sustainability. It became a key resource for stakeholders looking to improve urban mobility, offering a user-friendly way to explore complex data. The platform has been widely used and shared across various sectors, reinforcing HERE’s position as a leader in urban mobility solutions.",
    "awards": [],
    "collaborators": [],
    "hidden": true
  }
];

export const ABOUT_INFO = {
  name: "Daniel Samson",
  title: "Executive Creative Director at Subvrsive London",
  description: "Hey 👋 I'm a Creative Director 🎬, Designer 🎨 and Creative Technologist 🖥️ — your quintessential jack of all trades and master of some. Globetrotting from London 🇬🇧 to Singapore 🇸🇬 and Rotterdam 🇳🇱, I've honed my skills at top agencies like <a href='https://www.mediamonks.com/'>Media.Monks</a>, <a href='https://subvrsive.com/'>Subvrsive</a>, <a href='https://www.b-reel.com/'>B-Reel</a> and <a href='https://allofus.com/'>AllofUs</a>, crafting creative solutions that resonate globally. Armed with experience in AR 🕶️, a curiosity for VR 🌐 and an ever-growing fascination with AI 🤖, I create immersive experiences across Voice 🔊, 3D 🛠️, immersive web 💻 and experiential design 🎢. I enjoy leading interdisciplinary teams towards bold and interesting outcomes, aiming to produce work that is as delightful as it is effective. Constantly poking at the boundaries of the next possible, I'm excited to explore where both old and new technology might lead us.",
  imageUrl: "/images/dan-samson.jpg"
};
