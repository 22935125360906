export class PostProcessingPass {
  constructor(pass, name, config = {}) {
    this.pass = pass;
    this.name = name;
    this.config = config;
    this.enabled = true;
  }

  update(time, deltaTime) {
    // Override in specific pass implementations
  }

  updateUniforms(uniforms) {
    if (!this.pass?.uniforms) return;
    
    Object.entries(uniforms).forEach(([key, value]) => {
      if (this.pass.uniforms[key]?.value !== undefined) {
        this.pass.uniforms[key].value = value;
      }
    });
  }

  resize(width, height) {
    // Override if needed
  }

  dispose() {
    if (this.pass?.dispose) {
      this.pass.dispose();
    }
  }
} 